import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "Content-type": "application/json",
    }
})
const api2 = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "Content-type": "application/json",
    },
})

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("TOKEN");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
api2.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("REFRESH_TOKEN");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const refreshToken = async () => {
    try {
        const resp = await api2.get("auth/refresh");
        return resp.data;
    } catch (e) {
        console.log("Error", e);
    }
};

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            const tokens = await refreshToken();

            localStorage.setItem("TOKEN", tokens.TOKEN)
            localStorage.setItem("REFRESH_TOKEN", tokens.REFRESH_TOKEN)
            console.log("retry...");

            api.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${tokens.TOKEN}`;
            return api(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default (api)