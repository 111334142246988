import { Box, Card, CardContent, CardHeader, Fade, Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { connect, useDispatch } from "react-redux";
import { setMainTitle } from "../reduxActions/Actions";
import StripeEditCard from "./StripeEditCard";

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Payment() {
    const [payment, setPayment] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setMainTitle("Payment"))
    }, [])

    const handleChange = (event, newValue) => {
        setPayment(newValue)
    }

    return (
        <Fade in={true} timeout={450}>
            <div>
                {isBrowser ?
                    <BrowserView>
                        <Container sx={{ p: 2 }}>
                            <Toolbar />
                            <Card>
                                <CardHeader></CardHeader>
                                <CardContent sx={{ p: 4 }}>
                                    <Elements stripe={promise}>
                                        <StripeEditCard />
                                    </Elements>
                                </CardContent>
                            </Card>
                        </Container>
                    </BrowserView>
                    :
                    <MobileView>
                        <Container sx={{ p: 2 }}>
                            <Toolbar />
                            <Box>
                                <Elements stripe={promise}>
                                    <StripeEditCard />
                                </Elements>
                            </Box>
                        </Container>
                    </MobileView>
                }
            </div>
        </Fade >
    );
}

export default connect()(Payment)