import React from 'react';
import { Dialog, DialogActions, Button, Typography, DialogContent, DialogTitle, Slide, Box } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogUpgrade = props => (
  <div>
    <Dialog fullWidth={true} id={props.id} open={props.isUpgradeDialogOpen} onClose={props.closeUpgradeDialog} TransitionComponent={Transition}>
      <DialogTitle>{props.upgradeDialogTitle}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">{props.upgradeDialogContent}</Typography>
        <Box sx={{mt: 1}}><img src="/img/Plans.svg" /></Box>
      </DialogContent>
      <DialogActions sx={{}}>
        <Button sx={{mr: 1}} disableElevation onClick={props.closeUpgradeDialog}>Cancel</Button>
        <Button size='large' disableElevation variant='contained' component={NavLink} to={"/plans"} color="primary">
          {t("edit_profile_deny_button")}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default DialogUpgrade;