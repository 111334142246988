import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, IconButton, Button, Box, TextField, InputAdornment } from '@mui/material';
import { EditOutlined, HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import I18n from '../commons/Utils';
import { Trans } from 'react-i18next';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import api from '../services/api';

class ProfileInvestmentDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      disabled: 1,
      value: localStorage.getItem('INVUNIT'),

      snackOpen: false,
      snackMessage: ''
    }
  }

  async componentDidMount() {
  }

  changeValue() {
    
    api.put('users/settings/investment_unit', {name:"investment_unit", value: parseFloat(this.state.value) })
      .then(response => {
        const data = response.data
        localStorage.setItem('INVUNIT', parseFloat(this.state.value));
        this.setState({ disabled: 1, snackOpen: true, snackMessage:'Value changed' });
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  enableButton(value) {
    this.setState({ disabled: 0, value })
  }

  render() {
    const { disabled, snackOpen, snackMessage } = this.state;
    const { classes } = this.props;
    return (
      <>
        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />
        <Card variant="outlined">

          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>edit_profile_othersettings</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{ display: 'flex', p: 2 }}>
              <Box sx={{ flex: '65%' }}>
                <Typography variant="body1" component="h2"><Trans>edit_profile_investunit_text</Trans></Typography>
                <Typography color='secondary' variant="caption" component="h2"><Trans>edit_profile_investunit_sub</Trans></Typography>
              </Box>
              <Box sx={{ flex: '35%', display: 'flex', alignItems: 'center' }}>
                <TextField
                  label='Euro'
                  defaultValue={localStorage.getItem('INVUNIT')}
                  size='medium'
                  onKeyUp={(e) => this.enableButton(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditOutlined />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mr: 2 }} />
                <Button color="primary" disabled={disabled ? 1 : 0} onClick={() => this.changeValue()}><Trans>edit_profile_investunit_send</Trans></Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default ProfileInvestmentDemo;
