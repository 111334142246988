import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import SkeletonList2 from '../../elements/SkeletonList2';
import api from '../../services/api';

class MobDetailsFundamental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    
    api.get('trading/fundamental/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, isLoading } = this.state;
    return (
      isLoading ?
        <Box><SkeletonList2 rows={6} /></Box>
        :
        <Box>
          <Typography variant='overline'><Trans>{"DetailsFundamentalTitle"}</Trans></Typography>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{numeral(data.cap).format('0,000.00a').toUpperCase()} <small style={{ fontSize: 12 }}>{data.curr}</small></Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Market capitalization</Typography></Box>
              </Box>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.peratio}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>PE ratio</Typography></Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.earningsshare}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Earnings per share (EPS)</Typography></Box>
              </Box>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{numeral(data.ebitda).format('0,000.00a').toUpperCase()} <small style={{ fontSize: 12 }}>{data.curr}</small></Typography></Box>
                <Box><Typography variant='caption' color='secondary'>EBITDA</Typography></Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.dividendyield}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Dividend yield</Typography></Box>
              </Box>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.beta}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Beta</Typography></Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.quarterlyearningsgrowthyoy}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Quarterly earnings growth</Typography></Box>
              </Box>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.forwardpe}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Forward PE</Typography></Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.pricesalesttm}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Price/Sales</Typography></Box>
              </Box>
              <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                <Box><Typography variant='h6'>{data.pricebookmrq}</Typography></Box>
                <Box><Typography variant='caption' color='secondary'>Price/Book ratio</Typography></Box>
              </Box>
            </Box>
          </Box>
        </Box>
    );
  }
}

export default MobDetailsFundamental;
