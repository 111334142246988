import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from "@mui/material";
import { Trans } from 'react-i18next';
import api from '../services/api';

class MzReturn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      helpText: "",
    }
  }

  async componentDidMount() {
    
    api.get('v2/mz/wa/return/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
        localStorage.setItem("TITLE", data.name)
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    return (
      <Card variant="outlined">
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2">{data ? data.name : '-'}</Typography>}
          ></CardHeader>
        <CardContent>
          <Typography gutterBottom variant="overline" component="h2">
            {localStorage.getItem('MZPFN')}
          </Typography>
          <Typography className='big' variant="h3" component="h3">
            {data ? data.ret : '-'}
          </Typography>
          <Typography gutterBottom variant="caption" color={'secondary'} component="div">
            <Trans>mz_today_label_return</Trans>
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default MzReturn;
