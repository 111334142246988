import React, { Component } from 'react';
import { Container, Fade, Grid, Toolbar, Typography } from '@mui/material';
import ProfileDetails from '../cards/ProfileDetails';
import ProfileInvestmentDemo from '../cards/ProfileInvestmentDemo';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { notifyLoginUpdates, notifyLogout, setMainTitle } from '../reduxActions/Actions';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import ProfileDetailsMob from '../cards/ProfileDetailsMob';
import ProfileInvestmentDemoMob from '../cards/ProfileInvestmentDemoMob';

class MyProfile extends Component {

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) {
      this.props.dispatch(notifyLogout())
      this.props.dispatch(notifyLoginUpdates())
    }
    this.props.dispatch(setMainTitle("My Profile"))
  }

  render() {
    return (
      <>
        {isBrowser ?
          <BrowserView>
            <Fade in={true} timeout={450}>
              <div>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" className='big'><Trans>profile_pageTitle</Trans></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ProfileDetails />
                    </Grid>
                    <Grid item xs={12}>
                      <ProfileInvestmentDemo />
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Fade>
          </BrowserView>
          :
          <MobileView>
            <Fade in={true} timeout={450}>
              <div>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12}>
                  <Typography variant="h4" className='big'><Trans>profile_pageTitle</Trans></Typography>
                </Grid> */}
                    <Grid item xs={12}>
                      <ProfileDetailsMob />
                    </Grid>
                    {/* <Grid item xs={12}>
                  <ProfileInvestmentDemoMob />
                </Grid> */}
                  </Grid>
                </Container>
              </div>
            </Fade>
          </MobileView>
        }
      </>
    );
  }
}

export default connect()(MyProfile);