import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppWithRouter } from './App';
import './index.css';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import rootReducer from "./reduxReducers";
import registerServiceWorker from './serviceWorkerRegistration';
import { store } from './reduxStore/Store';

// const store = configureStore(rootReducer);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Provider store={store}>
      <AppWithRouter />
    </Provider>
  </HashRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


// This variable will save the event for later use.
//let deferredPrompt;
// window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevents the default mini-infobar or install dialog from appearing on mobile
//   e.preventDefault();
//   // Save the event because you'll need to trigger it later.
//   //deferredPrompt = e;

//   //alert("install Braino now!")
//   // Show your customized install prompt for your PWA
//   // Your own UI doesn't have to be a single element, you
//   // can have buttons in different locations, or wait to prompt
//   // as part of a critical journey.
//   showInAppInstallPromotion();
//   console.log('installable')
// });

// function showInAppInstallPromotion() {
//   //deferredPrompt.prompt();
//   console.log('installable2')
// }

// window.addEventListener('appinstalled', () => {
//   // Clear the deferredPrompt so it can be garbage collected
//   //deferredPrompt = null;
//   // Optionally, send analytics event to indicate successful install

// });