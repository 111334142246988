import { createTheme } from "@mui/material";
import { isMobile } from "react-device-detect";


const apptheme = createTheme({
  button: { textTransform: 'capitalize' },
  palette: {
    chartPrimary: '#8d21e9',
    chartSecondary: '#FF7400',
    colorPositive: '#33cd99',
    colorNegative: '#F00',
    grid: '#e6e6e6',
    sell: {
      light: '#A00',
      main: '#ff8e00',
      dark: 'F00',
      contrastText: '#fefefe',
    },
    buy: {
      light: '#33cd99',
      main: '#33cd99',
      dark: 'F00',
      contrastText: '#fefefe',
    },
    neg: {
      light: '#FF1744!important',
      main: '#FF1744!important',
      dark: '#FF1744!important',
      contrastText: '#fefefe!important',
    },
    pos: {
      light: '#0f0!important',
      main: '#33cd99!important',
      dark: 'F00!important',
      contrastText: '#fefefe!important',
    },
    background: {
      primary: (isMobile ? '#fafafa' : '#fafafa'),
      default: (isMobile ? '#fafafa' : '#fafafa'),
      paper: (isMobile ? '#fff' : '#fff'),
    },
    text: {
      primary: '#565656',
      secondary: '#a0a0a0',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primaryOnDark: {
      main: 'violet',
      light: 'purple',
      dark: 'violet',
      contrastText: '#fff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    success: {
      main: '#00FF00',
      light: '#00FF00',
      dark: '#00FF00',
      contrastText: '#FFFFFF',
    },
    successLight: {
      main: '#00FF0033',
      light: '#00FF00',
      dark: '#00FF00',
      contrastText: '#FFFFFF',
    },
    primary: {
      main: '#8d21e9',
      light: 'purple',
      dark: 'violet',
      contrastText: '#fff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#d4dadd',
      dark: '#045d56',
      main: '#aaa',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    golden: {
      main: '#ffd700',
      light: '#ffd700',
      dark: '#ffd700',
    },
    silver: {
      main: '#c0c0c0',
      light: '#c0c0c0',
      dark: '#c0c0c0',
    },
    bronze: {
      main: '#cd7f32',
      light: '#cd7f32',
      dark: '#cd7f32',
    },
    black: {
      main: '#848484',
      light: '#848484',
      dark: '#848484',
      contrastText: '#fff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    common: {
      black: '#848484'
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px!important',
          borderColor: '#f0f0f0'
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: '0!important',
        },
        action: {
          margin: 0
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: '0!important',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px!important',
          borderColor: '#f0f0f0!important',
          borderWidth: '1px!important',
          borderStyle: 'solid',
          boxShadow: 'none!important'
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0!important',
          borderColor: '#f0f0f0!important',
        },
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
        },
      }
    },
    MuiContainer: {
      root: {
        padding: '0!important',
        margin: '0'
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: '1px',
          minHeight: '20px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#8d21e9',
          color: '#fff',
          border: 0,
        },
        arrow: {
          //backgroundColor: '#8d21e9',
          color: '#8d21e9',
        }
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#a0a0a0!important',
          "&.Mui-active": {
            color: "#8d21e9!important"
          },
          "&.Mui-completed": {
            color: "#c289f3!important"
          }
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#faaf00!important'
        },
        iconEmpty: {
          color: '#e6e6e6!important'
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'Roboto', 'sans-serif'",
        },
        overline: {
          fontWeight: "bold",
          letterSpacing: '.1666666667em',
          lineHeight: 1.43
        },
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#565656",
          minWidth: "auto!important",
          "&.Mui-selected": {
            "color": "#8d21e9!important",
            "fontSize": "0.75rem!important"
          }
        },
        label: {
          "&.Mui-selected": {
            fontSize: "0.75rem",
          },
          fontSize: "0.75rem",
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
          color: '#132F4C',
          boxShadow: 'none',
          borderRadius: '0!important',
          border: "0px solid #8d21e9!important",
          borderColor: "#8d21e9!important",
          margin: 0,
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ccc!important',
        },
      }
    },
  },
});

export default apptheme;