import { Alert, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, Fade, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { Component } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setMainTitle } from '../reduxActions/Actions';
import api from '../services/api';
import ProfilingHorizon from './ProfilingHorizon';
import ProfilingMarkets from './ProfilingMarkets';
import ProfilingRisk from './ProfilingRisk';
import ProfilingSectors from './ProfilingSectors';

class ProfilingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      dialogText: '-',
      statuses: {
        q_1: false,
        q_2: false,
        q_3: false,
        q_4: false,
      },
      questions: {
        q_1: 0,
        q_2: 0,
        q_3: 0,
        q_4: 0,
      },
      activeStep: 0,
      activeStatus: true,
      steps: [
        {
          title: t("Profiling0ShortTitle"),
          label: t("Profiling0Title"),
          description: <Typography sx={{ fontWeight: 300 }}>{t("Profiling0SubTitle")}</Typography>,
        },
        {
          title: t("Profiling1ShortTitle"),
          label: t("Profiling1Title"),
          description: <ProfilingRisk handleChange={this.handleChange.bind(this)} handleStatus={this.handleStatus.bind(this)} q={'q_1'} />,
        },
        {
          title: t("Profiling2ShortTitle"),
          label: t("Profiling2Title"),
          description: <ProfilingHorizon handleChange={this.handleChange.bind(this)} handleStatus={this.handleStatus.bind(this)} q={'q_2'} />,
        },
        {
          title: t("Profiling3ShortTitle"),
          label: t("Profiling3Title"),
          description: <ProfilingMarkets handleChange={this.handleChange.bind(this)} handleStatus={this.handleStatus.bind(this)} q={'q_3'} />,
        },
        {
          title: t("Profiling4ShortTitle"),
          label: t("Profiling4Title"),
          description: <ProfilingSectors handleChange={this.handleChange.bind(this)} handleStatus={this.handleStatus.bind(this)} handleError={this.openDialog.bind(this)} q={'q_4'} />,
        },
      ],
      loadingText: t("Profilingend_1_text"),
      //loadingError: t("{loadingText}")
    }
  }

  componentDidMount() {
    this.props.dispatch(setMainTitle("Profiling"))
  }

  componentDidUpdate() {
    if (localStorage.getItem("QST") === "0") {
      console.log("Already profiled");
      this.props.navigate('/home', { replace: true })
    }
  }

  handleChange(question, value) {
    let questions = this.state.questions
    questions[question] = value
    this.setState({ questions })
  }

  handleStatus(question, status) {
    let statuses = this.state.statuses
    statuses[question] = status
    this.setState({ statuses, activeStatus: status })
  }

  handleNext() {
    let canStep = this.state.statuses['q_' + this.state.activeStep]
    if ((this.state.activeStep === 0 && !canStep) || canStep) {
      this.setState((prevState, props) => ({
        activeStep: prevState.activeStep + 1,
        activeStatus: false
      }));
    } else {
      this.openDialog()
    }
  }

  handleBack() {
    this.setState((prevState, props) => ({
      activeStep: prevState.activeStep - 1
    }));
  }

  handleSubmit() {
    this.setState({
      activeStep: -1,
      activeStatus: false
    });

    let payload = this.state.questions;
    api.post('questions/' + localStorage.getItem('ID'), payload)
      .then(response => {
        localStorage.setItem("QST", 0)
        this.props.navigate('/home')
      }).catch(error => {
        this.setState({ loadingText: 'Something went wrong. Please retry' })
        console.log(error);
      })
  }

  closeDialog() {
    this.setState({ dialogOpen: false })
  }

  openDialog(text) {
    this.setState({ dialogOpen: true, dialogText: text ? text : "Rispondi alla domanda prima di passare allo step successivo" })
  }

  // create function with console.log hello World

  render() {
    const { activeStep, activeStatus, steps, dialogOpen, dialogText, loadingText } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          {isBrowser ?
            <BrowserView>
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'>{t("ProfilingTitle")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Dialog open={dialogOpen} onClose={() => this.closeDialog()}>
                      <Alert action={<Button onClick={() => this.closeDialog()} color="inherit">ok</Button>} severity='warning' variant="filled">{dialogText}</Alert>
                    </Dialog>
                    <Card>
                      <CardHeader
                        title={<Typography gutterBottom variant="overline" component="h2">{t("ProfilingSubTitle")}</Typography>}
                      ></CardHeader>
                      <CardContent>
                        <Stepper sx={{ p: 3 }} activeStep={activeStep} orientation="vertical">
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel>
                                {activeStep === index ?
                                  <Typography variant='h6'>{step.label}</Typography>
                                  : <Typography variant='subtitle2'>{step.title}</Typography>
                                }
                              </StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                  <div>
                                    {index > 0 ?
                                      <>
                                        <Button
                                          disableElevation
                                          onClick={() => this.handleBack()}
                                          sx={{ mt: 1, mr: 1 }}
                                        >
                                          {t("ProfilingStartBack_button")}
                                        </Button>
                                        <Button
                                          disabled={!activeStatus}
                                          variant="contained"
                                          disableElevation
                                          onClick={() => this.handleNext()}
                                          sx={{ mt: 1, mr: 1 }}
                                        >
                                          {index === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStartNext_button")}
                                        </Button>
                                      </>
                                      :
                                      <Button
                                        variant="contained"
                                        disableElevation
                                        onClick={() => this.handleNext()}
                                        sx={{ mt: 1, mr: 1 }}
                                      >
                                        {index === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStart_button")}
                                      </Button>
                                    }
                                  </div>
                                </Box>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                        {activeStep === steps.length && (
                          <Paper square elevation={0} sx={{ p: 3 }}>
                            <Box sx={{ mb: 3 }}>
                              <Typography variant='h6'>{t("ProfilingLast_title")}</Typography>
                              <Typography variant='body2'>{t("ProfilingLast_text")}</Typography>
                            </Box>
                            <Button
                              disableElevation
                              onClick={() => this.handleBack()}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {t("ProfilingStartBack_button")}
                            </Button>
                            <Button disableElevation variant='contained' onClick={() => this.handleSubmit()} sx={{ mt: 1, mr: 1 }}>
                              {t("ProfilingLast_button")}
                            </Button>
                          </Paper>
                        )}

                        {activeStep === -1 && (
                          <Paper square elevation={0} sx={{ p: 3 }}>
                            <Box sx={{ mb: 3 }}>
                              <CircularProgress /> {loadingText}
                            </Box>
                          </Paper>
                        )}

                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container >
            </BrowserView>
            :
            <MobileView>
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Dialog open={dialogOpen} onClose={() => this.closeDialog()}>
                      <Alert action={<Button onClick={() => this.closeDialog()} color="inherit">ok</Button>} severity='warning' variant="filled">{dialogText}</Alert>
                    </Dialog>
                    {/* <Typography gutterBottom variant="overline" component="h2">{t("ProfilingSubTitle")}</Typography> */}

                    <Stepper sx={{ p: 3 }} activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel>
                            {activeStep === index ?
                              <Typography variant='h6'>{step.label}</Typography>
                              : <Typography variant='subtitle2'>{step.title}</Typography>
                            }
                          </StepLabel>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {index > 0 ?
                                  <>
                                    <Button
                                      disableElevation
                                      onClick={() => this.handleBack()}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      {t("ProfilingStartBack_button")}
                                    </Button>
                                    <Button
                                      disabled={!activeStatus}
                                      variant="contained"
                                      disableElevation
                                      onClick={() => this.handleNext()}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      {index === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStartNext_button")}
                                    </Button>
                                  </>
                                  :
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={() => this.handleNext()}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    {index === steps.length - 1 ? t("ProfilingStartFinish_button") : t("ProfilingStart_button")}
                                  </Button>
                                }
                              </div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Box sx={{ mb: 3 }}>
                          <Typography variant='h6'>{t("ProfilingLast_title")}</Typography>
                          <Typography variant='body2'>{t("ProfilingLast_text")}</Typography>
                        </Box>
                        <Button
                          disableElevation
                          onClick={() => this.handleBack()}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {t("ProfilingStartBack_button")}
                        </Button>
                        <Button disableElevation variant='contained' onClick={() => this.handleSubmit()} sx={{ mt: 1, mr: 1 }}>
                          Conferma il profilo e continua
                        </Button>
                      </Paper>
                    )}

                    {activeStep === -1 && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Box sx={{ mb: 3 }}>
                          <CircularProgress /> {loadingText}
                        </Box>
                      </Paper>
                    )}

                  </Grid>
                </Grid>
              </Container >
            </MobileView>
          }
        </div>
      </Fade>
    );
  }
}

function Profiling() {
  const navigate = useNavigate() // extract navigation prop here 
  const dispatch = useDispatch() // extract dispatch prop here 

  return <ProfilingBase navigate={navigate} dispatch={dispatch} /> //pass to your component.

}
export default connect()(Profiling);
