import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Fade, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import SkeletonList2 from '../elements/SkeletonList2';
import IntroHome from '../intro/IntroHome';
import MobIntroHome from '../intro/MobIntroHome';
import { notifyLoginUpdates, notifyLogout, setMainTitle } from '../reduxActions/Actions';
import api from '../services/api';

class MainOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading1: false,
      isLoading2: false,
      dataTrades: null,
      dataMz: null,
      introOpen: localStorage.getItem("ONB_HOME") ? false : true
    }
  }

  componentDidUpdate() {
    if (!localStorage.getItem("TOKEN")) {
      this.props.dispatch(notifyLogout())
      this.props.dispatch(notifyLoginUpdates())
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) {
      this.props.dispatch(notifyLogout())
    }
    this.props.dispatch(setMainTitle(localStorage.getItem("LN") * 1 > 0 ? t("homeWelcome2") : t("homeWelcome1")))

    numeral.options.scalePercentBy100 = false
    this.setState({ isLoading1: true, isLoading2: true });


    api.get('v1/data/users/' + localStorage.getItem('ID') + '/status/trades')
      .then(response => {
        const dataTrades = response.data
        this.setState({ dataTrades, isLoading1: false });

      }).catch(error => {
        console.log(error);
        this.setState({ isLoading1: false });
      })

    api.get('v1/data/users/' + localStorage.getItem('ID') + '/status/mz')
      .then(response => {
        const dataMz = response.data
        this.setState({ dataMz, isLoading2: false });

      }).catch(error => {
        console.log(error);
        this.setState({ isLoading2: false });
      })

  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  render() {
    const { isLoading1, isLoading2, introOpen, dataTrades, dataMz } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          {isBrowser ?
            <BrowserView>
              <Toolbar />
              <Container sx={{ p: 2 }}>

                <IntroHome
                  open={introOpen}
                  introClose={() => this.introClose()}
                  title={'Intro'}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'>{localStorage.getItem("LN") * 1 > 0 ? t("homeWelcome2") : t("homeWelcome1")}</Typography>
                    <Typography variant="body2" className='slim'>{t("homeWelcomeSubtitle")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" elevation={0}>
                      <CardContent sx={{ textAlign: 'center', p: 0, py: 2, bgcolor: '#8d21e9' }}>
                        <img height={200} alt="Portfolios" src="/img/MainOverviewPfolios.svg" />
                      </CardContent>
                      <CardHeader title={<Typography variant="h6"><Trans>menu_name_portfoliom</Trans></Typography>}
                        subheader={<Box sx={{ height: 38 }}><Typography color={'secondary'} variant="caption">{<Trans>onboarding_home1_text</Trans>}</Typography></Box>} />
                      <CardContent sx={{ my: 3 }}>
                        <>
                          <Typography variant='overline'>{t("homePortfoliosExcess")}</Typography>
                          {isLoading1 ? <SkeletonList2 /> :
                            dataMz ?
                              (
                                <List dense={false}>
                                  {
                                    dataMz.map((obj, index) => (
                                      <ListItem key={"pf_" + index}>
                                        <ListItemText primary={
                                          <Typography variant='subtitle2'>{obj.name}</Typography>
                                        } secondary={
                                          <Typography variant='caption' color='textSecondary' className='slim'>vs {obj.bm}</Typography>
                                        } />
                                        <ListItemSecondaryAction>
                                          <Typography variant='h6' className='big'>{numeral(obj.excess_return).format('+0.00%')}</Typography>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    ))
                                  }
                                </List>
                              ) :
                              <CircularProgress />
                          }
                        </>
                      </CardContent>
                      <CardActions>
                        <Button component={Link} to="/portfolios" variant="contained" color="primary" disableElevation>{t("homePortfoliosButton")}</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" elevation={0}>
                      <CardContent sx={{ textAlign: 'center', p: 0, py: 2, bgcolor: '#C289F3' }}>
                        <img height={200} alt="Trades" src="/img/MainOverviewTrades.svg" />
                      </CardContent>
                      <CardHeader title={<Typography variant="h6"><Trans>my_sectionTitle1</Trans></Typography>}
                        subheader={<Box sx={{ height: 38 }}><Typography color={'secondary'} variant="caption">{<Trans>onboarding_home2_text</Trans>}</Typography></Box>} />
                      <CardContent sx={{ my: 3 }}>
                        <Typography variant='overline'>Overview</Typography>
                        {isLoading2 ? <SkeletonList2 /> :
                          dataTrades ?
                            <>
                              <List dense={false}>
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesTradesTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' className='slim' color='secondary'>{t("homeTradesTradesSubtitle")}</Typography>
                                  } />
                                  <ListItemSecondaryAction>
                                    <Typography variant='h6' className='big'>{dataTrades.opened}</Typography>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesAdvicesTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' className='slim' color='secondary'>{t("homeTradesAdvicesSubtitle")}</Typography>
                                  } />
                                  <ListItemSecondaryAction><Typography variant='h6' className='big'>{dataTrades.advices}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesProfitTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' className='slim' color='secondary'>{t("homeTradesProfitSubtitle", { pos: dataTrades.profit.trades })}</Typography>
                                  } />
                                  <ListItemSecondaryAction><Typography variant='h6' className='big'>{numeral(dataTrades.profit.profit).format('+0.00%')}</Typography></ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            </>
                            : "No data"
                        }
                      </CardContent>
                      <CardActions>
                        <Button component={Link} to="/mybraino" variant="contained" color="primary" disableElevation>{t("homeTradesButton")}</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </BrowserView>
            :
            <MobileView>
              <Toolbar />
              <Container sx={{ p: 2 }}>

                <MobIntroHome
                  open={introOpen}
                  introClose={() => this.introClose()}
                  title={'Intro'}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="body2">{t("homeWelcomeSubtitle")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" elevation={0}>
                      <CardHeader title={<Typography variant="h6">My Portfolios</Typography>}
                        subheader={<Box><Typography color={'secondary'} variant="caption">{<Trans>onboarding_home1_text</Trans>}</Typography></Box>} />
                      <CardContent sx={{ my: 3 }}>
                        <>
                          <Typography variant='overline'>{t("homePortfoliosExcess")}</Typography>
                          {isLoading1 ? <SkeletonList2 /> :
                            dataMz ?
                              (
                                <List dense={true}>
                                  {
                                    dataMz.map((obj, index) => (
                                      <ListItem key={"item_" + index}>
                                        <ListItemText primary={
                                          <Typography variant='subtitle2'>{obj.name}</Typography>
                                        } secondary={
                                          <Typography variant='caption' color='secondary'>vs {obj.bm}</Typography>
                                        } />
                                        <ListItemSecondaryAction>
                                          <Typography variant='h6' className='big'>{numeral(obj.excess_return).format('+0.00%')}</Typography>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    ))
                                  }
                                </List>
                              ) :
                              <CircularProgress />
                          }
                        </>
                      </CardContent>
                      <CardActions>
                        <Button component={Link} to="/portfolios" variant="contained" color="primary" disableElevation>{t("homePortfoliosButton")}</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" elevation={0}>
                      <CardHeader title={<Typography variant="h6"><Trans>my_sectionTitle1</Trans></Typography>}
                        subheader={<Box><Typography color={'secondary'} variant="caption">{<Trans>onboarding_home2_text</Trans>}</Typography></Box>} />
                      <CardContent sx={{ my: 3 }}>
                        <Typography variant='overline'>Overview</Typography>
                        {isLoading2 ? <SkeletonList2 /> :
                          dataTrades ?
                            <>
                              <List dense={true}>
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesTradesTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' color='secondary'>{t("homeTradesTradesSubtitle")}</Typography>
                                  } />
                                  <ListItemSecondaryAction>
                                    <Typography variant='h6' className='big'>{dataTrades.opened}</Typography>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesAdvicesTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' color='secondary'>{t("homeTradesAdvicesSubtitle")}</Typography>
                                  } />
                                  <ListItemSecondaryAction><Typography variant='h6' className='big'>{dataTrades.advices}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                  <ListItemText primary={
                                    <Typography variant='subtitle2'>{t("homeTradesProfitTitle")}</Typography>
                                  } secondary={
                                    <Typography variant='caption' color='secondary'>Profitto su {dataTrades.profit.trades} posizioni già chiuse</Typography>
                                  } />
                                  <ListItemSecondaryAction><Typography variant='h6' className='big'>{numeral(dataTrades.profit.profit).format('+0.00%')}</Typography></ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            </>
                            : "No data"
                        }
                      </CardContent>
                      <CardActions>
                        <Button component={Link} to="/mybraino" variant="contained" color="primary" disableElevation>{t("homeTradesButton")}</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </MobileView>
          }
        </div>
      </Fade>
    );
  }
}

export default connect()(MainOverview);