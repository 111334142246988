import styled from "@emotion/styled";
import { Alert, Box, Button, CircularProgress, Collapse, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import { t } from 'i18next';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import api from "../services/api";

const CardInputWrapper = styled.div`
    border: 1px solid #b9b9bf;
  border-radius: 4px;
  padding: 16px;
`;

const initialFormData = Object.freeze({
    payment_type: 1,
    id_product: 1,
    name: "",
    last_name: "",
    country: "",
    billing: "1",
});

export default function PaypalCheckout() {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [currency, setCurrency] = useState('');
    const [recurring, setRecurring] = useState('');
    const [countries, setCountries] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountDisplay, setAmountDisplay] = useState(0);
    const [formData, updateFormData] = React.useState(initialFormData);


    useEffect(() => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
        api.get('shop/countries')
            .then(response => {
                const data = response.data
                setCountries(data)
            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })

    }, []);

    React.useEffect((event) => {
        chechFormData()
        if (formData.country && formData.billing) fetchPrice()
    }, [formData.country]);

    React.useEffect((event) => {
        chechFormData()
        if (formData.country && formData.billing) fetchPrice()
    }, [formData.billing]);

    React.useEffect((event) => {
        chechFormData()
    }, [formData.name]);

    React.useEffect((event) => {
        chechFormData()
    }, [formData.last_name]);

    const handleChange = async (event) => {
        if (event.target)
            updateFormData({
                ...formData,
                // Trimming any whitespace
                [event.target.name]: event.target.value.trim()
            });

        chechFormData()
    };

    const chechFormData = () => {
        const allValuesAreTruthy = Object.values(formData).every((value) => Boolean(value));
        if (allValuesAreTruthy) setDisabled(false);
        else setDisabled(true)
    }

    const fetchPrice = async () => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
        api.get('shop/price_info/' + formData.id_product + '/' + formData.country + '/' + formData.billing)
            .then(response => {
                const data = response.data
                setAmount(data.amount * 100)
                setCurrency(data.currency)
                setAmountDisplay(data.amount)
                setRecurring(data.freq)
            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const formDataObject = new FormData();
        for (const property in formData) {
            formDataObject.append(property, formData[property]);
        }

        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
        api.post('shop/confirm_order/' + localStorage.getItem('ID'), formDataObject)
            .then(response => {
                const data = response.data
                setSucceeded(true)
                setTimeout(() => {
                    window.location = data
                }, 2500);
            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                <Typography variant="overline" gutterBottom><Trans>ecommerce_invoicing_data</Trans></Typography>
                <FormControl variant="outlined" sx={{ pb: 2 }}>
                    Email: {localStorage.getItem("EMAIL")}
                </FormControl>
                <FormControl variant="outlined" sx={{ pb: 2 }}>
                    <TextField sx={{ width: '100%' }}
                        required
                        name="name"
                        onChange={handleChange}
                        label={<Trans>ecommerce_firstname_label</Trans>}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ pb: 2 }}>
                    <TextField sx={{ width: '100%' }}
                        required
                        name="last_name"
                        onChange={handleChange}
                        label={<Trans>ecommerce_lastname_label</Trans>}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={{ pb: 2 }}>
                    <InputLabel id="country-select"><Trans>ecommerce_country_label</Trans> *</InputLabel>
                    <Select name="country"
                        onChange={handleChange} defaultValue={0} value={formData.country} id="country-select" label="Country">
                        {countries && countries.map(item =>
                        (<MenuItem value={item["2_code"]}>
                            {item.name}
                        </MenuItem>)
                        )}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ pb: 2 }}>
                    <FormLabel id="billing-select"><Typography variant="body2"><Trans>ecommerce_billing_label</Trans> *</Typography></FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        id="billing-select"
                        name="billing"
                        value={formData.billing}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="1" control={<Radio />} label={t("ecommerce_billing_month_label")} />
                        <FormControlLabel value="2" control={<Radio />} label={t("ecommerce_billing_year_label")} />
                    </RadioGroup>
                </FormControl>
                <FormControl variant="outlined" sx={{ pb: 2 }}>
                    <Collapse in={currency}>
                        <Typography variant="subtitle1">
                            <b>
                                Price: {currency ? (new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(amountDisplay)) + "/" + recurring : "Select your Coutry"}
                            </b>
                        </Typography>
                    </Collapse>
                </FormControl>
                <FormControl variant="outlined" sx={{ py: 2 }}>
                    <Button type="submit" variant="contained" size="large" disabled={processing || disabled || succeeded} disableElevation><Trans>ecommerce_subscribe</Trans></Button>
                </FormControl>
                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}
                {/* Show a success message upon completion */}
                <Collapse timeout={150} in={succeeded}
                    sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%!important", bgcolor: "#e6e6e6", zIndex: 1, p: 3 }}>
                    <Box sx={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {/* <Alert icon={<CircularProgress size={24} sx={{}} />} color="success"> */}
                        <Box sx={{ mt: 3 }}><CircularProgress size={25} /></Box>
                        <Typography variant="body1">Redirecting to Paypal...</Typography>
                        {/* <Box sx={{mt: 3}}><LinearProgress sx={{width: 200}} /></Box> */}
                        {/* </Alert> */}
                    </Box>
                </Collapse>
            </Box>
        </form >
    );
}