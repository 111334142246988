import React, { Component } from 'react';

const countries = {
    Belgium: 'BEL', Denmark: 'DNK', Norway: 'NOR', Germany: 'DEU', Sweden: 'SWE', Russia: 'RUS', 'Spain': 'ESP', 'Italy': 'ITA', France: 'FRA', India: 'IND', UnitedStates: 'USA', UnitedKingdom: 'GBR', Netherlands: 'NLD', Ireland: 'IRL', HongKong: "HKG", Switzerland: "CHE"
    , "Japan": "JPN", "Argentina": "ARG", "Finland": "FIN", "Mexico": "MX"
};
export const getFlag = (country) => {
    let flagpath = country ?
        '/img/flags/' + countries[country.replace(' ', '')] + '.png'
        : ''
    return flagpath
}

const langs = {
    help_addpos: {
        it: "testo",
        en: "text"
    },
    "my_open_advice_title": {
        "en": "TODAY'S ADVICE",
        "it": "CONSIGLI DEL GIORNO"
    },
    "my_open_advice_help": {
        "en": "The card provides you with a selection of Braino's decisions at the current date (*). For each trade the following is indicated: the name of the security, the direction of the position to open bullish (\"long\") or bearish (\"short\"), and the rating. The time horizon of the trade is variable and decided independently by Braino (who will warn you if you add the trade in <i>My positions</i>).<br><br> By clicking the button <i class=\"material-icons-outlined material-icons-outlined-blue\">alarm_add</i> you add the trade to <i>My positions</i>, and this allows you to monitor the trend and performance over time, and to be informed by Braino when it is time to close the position. If you do not add the trade to the <i>My positions</i> card, Braino considers that the trade is not of interest to you and will not provide you with instructions on how to manage/close the position.<br><br><div class=\"mdc-typography--body2\">(*) In your MyBraino account you can only see a small selection of the decisions that Braino makes every day, selected according to your profile and habits.  If you are a professional and would like access to more of Braino's trades, please <a href=\"mailto:info@wavenure.com?subject=Info request\" class=\"link_style action_feedback\">contact us</a></div>",
        "it": "La card ti fornisce una selezione delle decisioni di Braino alla data corrente (*). Per ogni operazione sono indicati: il nome del titolo, la direzione di apertura posizione al rialzo (long) o al ribasso (short) e il rating. La durata dell'operazione è variabile e decisa autonomamente da Braino (che ti avvertirà se aggiungi l'operazione in <i>Le mie posizioni</i>).<br><br>Cliccando il pulsante <i class=\"material-icons-outlined material-icons-outlined-blue\">alarm_add</i> aggiungi la posizione operativa alla card <i>Le mie posizioni</i>; ciò ti permette di monitorare l'andamento e la performance nel tempo e di essere informato da Braino quando è il momento di chiudere la posizione. Se non aggiungi l'operazione alla card <i>Le mie posizioni</i>, Braino ritiene che l'operazione non ti interessa e non ti fornirà indicazioni per la gestione/chiusura della posizione.<br><br><div class=\"mdc-typography--body2\">(*) nel tuo account MyBraino puoi vedere solo una piccola selezione delle decisioni che Braino prende ogni giorno, selezionate in base al tuo profilo ed abitudini. Se sei un professionista e vuoi accedere ad una più ampia operatività di Braino, <a href=\"mailto:info@wavenure.com?subject=Richiesta informazioni\" class=\"link_style action_feedback\">contattaci</a></div>"
    },
    mz_today_title: {
        en: "Total Return",
        it: "Total Return"
    },
    mz_today_label_return: {
        en: "Return",
        it: "Rendimento"
    },
    mz_fingerprint_title: {
        en: "Fingerprint",
        it: "Fingerprint"
    },
    mz_fingerprint_label_compare: {
        en: "Compare",
        it: "Confronta"
    },
    mz_fingerprint_label_return: {
        en: "Return",
        it: "Rendimento"
    },
    mz_fingerprint_label_95var: {
        en: "95-VaR",
        it: "95-VaR"
    },
    mz_fingerprint_label_sortino: {
        en: "Sortino",
        it: "Sortino"
    },
    mz_fingerprint_label_Drawdown: {
        en: "Drawdown",
        it: "Drawdown"
    },
    mz_fingerprint_label_Sharpe: {
        en: "Sharpe",
        it: "Sharpe"
    },
    mz_fingerprint_label_alpha: {
        en: "Alpha",
        it: "Alpha"
    },
    mz_fingerprint_help: {
        "en": "The Kiviat diagram in the card shows at a glance the salient characteristics of the observed portfolio compared to other available portfolios.<br>The scale of the chart is calibrated to show higher scores (near the outer edges) for values more \"favorable\" for the investor. Therefore, for example, high Return values and low Drawdown values, will both produce higher scores in the chart.<br>For more information about the indicators, see the RISK ANALYSIS card.<br>By clicking the button on the right-bottom corner of the tab you can compare the current portfolio with another one.",
        "it": "Il diagramma di Kiviat della card mostra a colpo d'occhio le caratteristiche salienti del portafoglio rispetto agli altri portafogli disponibili.<br>La scala del chart \u00e8 tarata in modo da mostrare punteggi pi\u00f9 alti (vicino ai bordi esterni) per valori degli indicatori pi\u00f9 \"favorevoli\" per l'investitore. Pertanto, ad esempio, valori elevati di Return e ridotti di Drawdown, produrranno entrambi punteggi elevati nel chart.<br>Per maggiori informazioni riguardo gli indicatori si rimanda alla scheda RISK ANALYSIS.<br>Tramite il pulsante posto nell'angolo in basso a destra della scheda \u00e8 possibile eseguire il confronto con un altro portafoglio."
    },
    mz_portfEvolution_title: {
        en: "Portfolio Evolution",
        it: "Portfolio Evolution"
    },
    mz_portfEvolution_label_compare: {
        en: "Compare with benchmark",
        it: "Confronta con benchmark"
    },
    mz_portfEvolution_help: {
        en: "The card follows the evolution of the portfolio return over time, compared with the one of the related benchmark.", //<br>Through the button located in the bottom-right corner of the card it is possible to view the trend of the invested capital in absolute terms.",
        it: "La scheda segue l'evoluzione del rendimento del portafoglio nel tempo, comparato con quella del relativo benchmark." //<br>Attraverso il pulsante posto nell'angolo in basso a destra della scheda è possibile visionare l'andamendo del capitale investito in termini assoluti."
    },
    mz_portfAllocation_title: {
        en: "Portfolio Allocation",
        it: "Portfolio Allocation"
    },
    mz_portfAllocation_button_sectors: {
        en: "Sectors",
        it: "Settori"
    },
    mz_portfAllocation_button_markets: {
        en: "Markets",
        it: "Mercati"
    },
    mz_portfAllocation_button: {
        en: "Allocation",
        it: "Composizione"
    },
    mz_portfAllocation_desc: {
        en: "The list of portfolio components with the related percentage weight.<br>Also position directions are indicated (Long or Short).",
        it: "La lista dei componenti del portafoglio con il relativo peso percentuale.<br>Inoltre vengono indicate anche le direzioni delle posizioni (Long o Short)."
    },
    mz_portfAllocation_denied: {
        en: "Are you a professional and want to know more details about our portfolios' composition? <a href=\"mailto:professionals@wavenure.com\" target=\"_blank\">Contact us</a>.",
        it: "Sei un professionista e vuoi conoscere maggiori dettagli sulla composizione dei nostri portafogli? <a href=\"mailto:professionals@wavenure.com\" target=\"_blank\">Contattaci</a>."
    },
    mz_portfAllocation_upgrade_btn: {
        en: "Upgrade your plan",
        it: "Aggiorna il piano"
    },
    mz_portfAllocation_help: {
        en: "The card carries out the breakdown of the current portfolio by grouping the instruments by operating sectors (or by belonging market).<br>By clicking on the button at the bottom-right you can see in details the whole list of components with along with the related weight in the portfolio.",
        it: "La scheda effettua il breakdown del portafolio attuale, raggruppando i titoli per settori operativi o per mercato di appartenenza.<br>Tramite il pulsante posto in basso a destra è possibile consultare nel dettaglio l'intero paniere di titoli con il relativo peso percentuale nel portafoglio."
    },
    mz_performance_title: {
        en: "Performance Report",
        it: "Performance Report"
    },
    mz_performance_button: {
        en: "More details",
        it: "Maggiori dettagli"
    },
    mz_performance_help: {
        en: "The card presents the performance obtained by the portfolio on different time windows. In particular it shows, among the others, the profit obtained in the last month, in the last 90 days, in the last year and since the beginning of the current year.<br>Through the button at the bottom of the tab more data is shown.",
        it: "La scheda presenta le performance ottenute dal portafoglio su diverse finestre temporali. In particolare mostra, tra gli altri, il profitto ottenuto nell'ultimo mese, negli ultimi 90 giorni, nell'ultimo anno e dall'inizio dell'anno corrente.<br>Tramite il pulsante nella parte inferiore della scheda vengono mostrati più dati."
    },
    mz_performance_label_inception: {
        en: "From inception",
        it: "Dall'inizio"
    },
    mz_performance_label_1M: {
        en: "Last month",
        it: "Ultimo mese"
    },
    mz_performance_label_90D: {
        en: "Last 3 months",
        it: "Ultimi 3 mesi"
    },
    mz_performance_label_1Y: {
        en: "Last year",
        it: "Ultimo anno"
    },
    mz_performance_label_YTD: {
        en: "Year to date",
        it: "Dal 1 gennaio"
    },
    mz_performance_label_alpha: {
        en: "Alpha",
        it: "Alpha"
    },
    mz_risk_title: {
        en: "Risk Analysis",
        it: "Risk Analysis"
    },
    mz_risk_button: {
        en: "More details",
        it: "Maggiori dettagli"
    },
    mz_risk_help: {
        en: "The card lists the calculated values of several risk indices that are significant for the portfolio.<br>The detail pop-up provides more information for each index.",
        it: "La scheda elenca i valori calcolati di diversi indici di rischio significativi per il portafoglio.<br>Nel popup di dettaglio vengono fornite maggiori informazioni per ciascun indice."
    },
    mz_risk_label_beta: {
        en: "Beta",
        it: "Beta"
    },
    mz_risk_desc_beta: {
        en: "Beta index is a measure of the volatility - or systematic risk - of an instrument or portfolio compared to the market as a whole.<br>Beta is used in the capital asset pricing model (CAPM), which describes the relationship between systematic risk and expected return for assets (usually stocks) or portfolios. CAPM is widely used as a method for pricing risky securities and for generating estimates of the espected returns of assets, considering both risk of those assets and the cost of capital",
        it: "L'indice Beta è una misura della volatilità - o del rischio sistematico - di un titolo o di un portafoglio rispetto al mercato nel suo complesso.<br>Beta è utilizzato nel modello di pricing del capitale (CAPM), che descrive la relazione tra il rischio sistematico e il rendimento atteso dei titoli o dei portafogli. Il CAPM è ampiamente utilizzato come metodo per la determinazione del prezzo dei titoli rischiosi e per generare stime dei rendimenti attesi delle attività, considerando sia il rischio di tali attività che il costo del capitale."
    },
    mz_risk_label_var_95: {
        en: "95-VaR",
        it: "95-VaR"
    },
    mz_risk_desc_var_95: {
        en: "<i>Value-at-Risk</i> is a statistical measure of the riskiness of financial instruments or portfolios. It is defined as the maximum value expected to be lost over a given time horizon at a given confidence level if the worst case scenario occurs.<br>The time horizon is set to 1 day, while the confidence level is 95%",
        it: "Il Value-at-Risk è una misura statistica della rischiosità di strumenti finanziari o di portafogli. È definito come la massima perdita attesa su un determinato orizzonte temporale, con un dato livello di confidenza se si dovesse verificare il peggior scenario possibile.<br>L'orizzonte temporale è pari ad 1 giorno ed il livello di confidenza è fissato al 95%"
    },
    mz_risk_label_var_99: {
        en: "99-Var",
        it: "99-Var"
    },
    mz_risk_desc_var_99: {
        en: "<i>Value-at-Risk</i> is a statistical measure of the riskiness of financial instruments or portfolios. It is defined as the maximum value expected to be lost over a given time horizon at a given confidence level if the worst case scenario occurs.<br>The time horizon is set to 1 day, while the confidence level is 99%",
        it: "Il <i>Value-at-Risk</i> è una misura statistica della rischiosità di strumenti finanziari o di portafogli. È definito come la massima perdita attesa su un determinato orizzonte temporale, con un dato livello di confidenza se si dovesse verificare il peggior scenario possibile.<br>L'orizzonte temporale è pari ad 1 giorno ed il livello di confidenza è fissato al 99%"
    },
    mz_risk_label_sharpe: {
        en: "Sharpe Ratio",
        it: "Indice di Sharpe"
    },
    mz_risk_desc_sharpe: {
        en: "The Sharpe ratio is a <i>Risk Adjusted Performance</i> (RAP), i.e. a performance indicator that also takes risk into account. It is calculated as the ratio between the difference, over the same period, of the portfolio yield and the yield of a 'risk-free' asset (e.g. short-term government bond) on the standard deviation (volatility). <p class=\"align-center\"><img src=\"img/mz_sharpe_ratio.png\" /></p>. This indicator is useful for comparing and classifying mainly homogeneous portfolios by benchmark. The larger the index the greater the profit based on the risk level.",
        it: "L'indice di Sharpe è un <i>Risk Adjusted Performance</i> (RAP), ovvero un indicatore di performance che tiene anche conto del rischio.<br>E' calcolato come rapporto tra la differenza, nello stesso periodo considerato, del rendimento del portafoglio e del rendimento di un'attività \"priva di rischio\" (es. titolo di stato a breve termine) sulla deviazione standard (volatilità). <p class=\"align-center\"><img src=\"img/mz_sharpe_ratio.png\" /></p> Tale indicatore è utile per confrontare e classificare portafogli omogenei per benchmark. Maggiore è il valore dell'indice, maggiore sarà il guadagno in base al livello di rischio"
    },
    mz_risk_label_sortino: {
        en: "Sortino Ratio",
        it: "Indice di Sortino"
    },
    mz_risk_desc_sortino: {
        en: "The Sortino ratio is very similar to the Sharpe ratio except for the denominator, where it is only considered the DownSide Risk. <p class=\"align-center\"><img src=\"img/mz_sortino_ratio.png\" /></p>This index allows you to discriminate instruments that have similar Sharpe ratio. A high Sortino ratio means that, even if there is high volatility, this is concentrated above the <i>free risk</i> rate considered acceptable.",
        it: "L'indice di Sortino è molto simile all'indice di Sharpe tranne che per il denominatore, dove viene considerata soltanto la volatilità a ribasso (DownSide Risk). <p class=\"align-center\"><img src=\"img/mz_sortino_ratio.png\" /></p>L'indice consente di discriminare portafogli che hanno indice di Sharpe simile. Un indice di Sortino elevato implica che, anche se in presenza di volatilità elevata, questa si concentri sopra il tasso <i>free risk</i> ritenuto accettabile."
    },
    mz_risk_label_volatility: {
        en: "Volatility",
        it: "Volatilità"
    },
    mz_risk_desc_volatility: {
        en: "Volatility is a statistical measure of the dispersion of returns for a given security or market index. The number displayed in the card is the annualized historical volatility which is calculated as follows:<br><p class=\"align-center\"><img src=\"img/mz_volatility.png\" /></p><br>dove gli <em>r<sub>i</sub></em> sono i ritorni giornalieri e <span style=\"text-decoration:overline\"><em>r</em></span> the average return.",
        it: "La volatilità è una misura statistica della dispersione dei rendimenti per un dato titolo o gruppo di titoli. Il valore visualizzato nella scheda è la volatilità storica annualizzata che viene calcolata come segue:<br><p class=\"align-center\"><img src=\"img/mz_volatility.png\" /></p><br>dove gli <em>r<sub>i</sub></em> sono i ritorni giornalieri e <span style=\"text-decoration:overline\"><em>r</em></span> il rendimento medio."
    },
    mz_risk_label_conditional_var_95: {
        en: "Conditional VaR-95",
        it: "Conditional VaR-95"
    },
    mz_risk_desc_conditional_var_95: {
        en: "Conditional Value at Risk (CVaR), also known as the <i>Expected Shortfall</i>, is a risk assessment measure that quantifies the amount of tail risk of an investment portfolio. <i>CVaR</i> is derived by considering a weighted average of the “extreme” losses in the tail (confidence interval at 95%) of the distribution of possible returns, beyond the value at risk (VaR) cut-off point. Conditional value at risk is used in portfolio optimization for an effective risk management.",
        it: "Il Conditional Value at Risk (CVaR), noto anche come <i>Expected Shortfall</i>, è una misura di valutazione del rischio che quantifica l'entità del tail risk di un portafoglio di investimenti. Il <i>CVaR</i> si ricava considerando una media ponderata delle perdite \"estreme\" nella coda (intervallo di confidenza al 95%) della distribuzione dei possibili rendimenti, oltre il punto di cut-off del valore a rischio (VaR). Il valore condizionale a rischio è utilizzato nell'ottimizzazione del portafoglio per un'efficace gestione del rischio."
    },
    mz_risk_label_conditional_var_99: {
        en: "Conditional VaR-99",
        it: "Conditional VaR-99"
    },
    mz_risk_desc_conditional_var_99: {
        en: "Conditional Value at Risk (CVaR), also known as the <i>Expected Shortfall</i>, is a risk assessment measure that quantifies the amount of tail risk of an investment portfolio. <i>CVaR</i> is derived by considering a weighted average of the “extreme” losses in the tail (confidence interval at 99%) of the distribution of possible returns, beyond the value at risk (VaR) cut-off point. Conditional value at risk is used in portfolio optimization for an effective risk management.",
        it: "Il Conditional Value at Risk (CVaR), noto anche come <i>Expected Shortfall</i>, è una misura di valutazione del rischio che quantifica l'entità del tail risk di un portafoglio di investimenti. Il <i>CVaR</i> si ricava considerando una media ponderata delle perdite \"estreme\" nella coda (intervallo di confidenza al 99%) della distribuzione dei possibili rendimenti, oltre il punto di cut-off del valore a rischio (VaR). Il valore condizionale a rischio è utilizzato nell'ottimizzazione del portafoglio per un'efficace gestione del rischio."
    },
    mz_drawdown_title: {
        en: "Drawdown Trend",
        it: "Drawdown Trend"
    },
    mz_drawdown_label_maxDrawdown: {
        en: "Max drawdown",
        it: "Max drawdown"
    },
    mz_drawdown_button: {
        en: "Compare with benchmark",
        it: "Confronta col benchmark"
    },
    mz_drawdown_help: {
        en: "The card shows the trend of the portfolio drawdown during the observation period. The drawdown represents  the decrease in percentage value of the portfolio since the last absolute maximum peak.<br>The Max Drawdown, i.e. the maximum value reached, is shown at the top right.", //<br>Using the button at the bottom of the card you can add the comparison with a benchmark.",
        it: "La scheda mostra l'andamento del drawdown del portafoglio durante il periodo di osservazione. Il drawdown indica la diminuzione di valore percentuale del portafoglio dall'ultimo massimo assoluto.<br>In alto a destra viene indicato il Max Drawdown, ovvero il valore massimo raggiunto." //<br>Tramite il pulsante in basso è possibile aggiungere il confronto con un benchmark."
    },

    // INTROS SECTION -----
    intro_button: {
        en: "Ok",
        it: "Ok"
    },
    intro_forever_button: {
        en: "Don't show it again",
        it: "Non mostrarlo più"
    },

    mz_intro_title: {
        en: "Portfolios",
        it: "Portafogli"
    },
    mz_intro_desc: {
        en: "Portfolios introduce capital management with a static active strategy, less responsive to market trends and focused on the long term. <br> The portfolios in this section will maintain their composition longer than Dynamic Portfolios and will be periodically rebalanced by Braino's AI.",
        it: "I portafogli introducono la gestione del capitale con una strategia attiva statica, meno reattiva rispetto ai trend di mercato e incentrata sul lungo periodo.<br>I portafogli in questa sezione manterranno la loro composizione più a lungo rispetto ai Portafogli Dinamici e subiranno dei ribilanciamenti periodici generati dalla AI di Braino."
    },
}

export default class I18n extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: ''
        }
    }

    async componentDidMount() {
        if (langs[this.props.prefix + "_" + this.props.type]) {
            this.setState({ text: langs[this.props.prefix + "_" + this.props.type][localStorage.getItem('LANG')] });
        } else {
            this.setState({ text: '[N.A.]' });
        }
    }

    render() {
        const { text } = this.state;
        return (
            <span>{text}</span>
        );
    }
}

