import React, { Component } from 'react';
import { Skeleton } from '@mui/material';
import { withTheme } from '@emotion/react';

class MySkeleton extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  runCallback = (cb) => {
    return cb();
  };

  render() {
    return (
      <div style={{ verticalAlign: 'top' }}>
        {this.props.type === 'table' ?
          (
            this.runCallback(() => {
              const row = [];
              for (var i = 0; i < this.props.rows; i++) {
                if (i === 0) {
                  row.push(<div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px' }}>
                    <span style={{ width: '33%' }}></span>
                    <Skeleton style={{ width: '33%' }} height={20} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={20} animation={this.props.animation}></Skeleton>
                  </div>)
                }
                row.push(
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px' }}>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                  </div>
                );
              }
              return row;
            })
          )
          :
          (this.props.type === 'chart' ?
            (<Skeleton variant='rect' height={this.props.heigt ? this.props.heigt : '100%'} animation={this.props.animation}></Skeleton>) :
            "")
        }
      </div>
    )

  }
}

export default withTheme(MySkeleton);
