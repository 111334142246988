import { Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardActions, CardContent, CardHeader, Fade, Grid, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { t } from 'i18next';
import React, { Component } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import MobIntroHome from '../intro/MobIntroHome';
import { setMainTitle } from '../reduxActions/Actions';
import api from '../services/api';

class Identify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      dataTrades: null,
      dataMz: null,
      introOpen: localStorage.getItem("ONB_HOME") ? false : true,
      sent: false,
    }
  }

  componentDidMount() {
    this.props.dispatch(setMainTitle("Identify"))
  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  resend = () => {
    this.setState({ isLoading: true })
    api.post('validation/' + localStorage.getItem('ID'), "")
      .then(response => {
        this.setState({ sent: true })
      }).catch(error => {
        console.log(error);
      })
  }

  render() {
    const { isLoading, introOpen, dataTrades, dataMz } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          {isBrowser ?
            <BrowserView>
              <Toolbar />
              <Container sx={{ p: 2 }}>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'>{t("reg_validationTitle")}</Typography>
                    <Typography variant="body2">{localStorage.getItem("EMAIL")}</Typography>
                  </Grid>
                  <Grid item xs={12}>

                    <Card variant="outlined" elevation={0}>
                      <CardHeader title={<Typography variant="overline"> {!this.state.sent ? t("reg_validationTitle") : t("reg_resentTitle")} </Typography>} />
                      <CardContent sx={{ py: 3 }}>
                        <>
                          <Box sx={{ textAlign: "center" }}>
                            {!this.state.sent ?
                              <img height={400} alt="Portfolios" src="/img/validationRequired.svg" />
                              : <img height={400} alt="Portfolios" src="/img/validationSent.svg" />}
                          </Box>
                          <Typography variant='body1' sx={{ fontWeight: 300 }}>{
                            !this.state.sent ?
                              <Trans components={{ br: <br /> }}>reg_validation_body</Trans>
                              : <Trans components={{ br: <br /> }}>reg_resent_body</Trans>
                          }</Typography>
                        </>
                      </CardContent>
                      <CardActions >
                        {!this.state.sent && <LoadingButton loading={isLoading} variant="contained" color="primary" loadingPosition="start" startIcon={<Send />} disableElevation onClick={this.resend}>{t("reg_validation_button")}</LoadingButton>}
                      </CardActions>
                    </Card>

                  </Grid>
                </Grid>
              </Container>
            </BrowserView>
            :
            <MobileView>
              <Toolbar />
              <Container sx={{ p: 2 }}>

                <MobIntroHome
                  open={introOpen}
                  introClose={() => this.introClose()}
                  title={'Intro'}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <>
                      <Box sx={{ textAlign: "center" }}>
                        {!this.state.sent ?
                          <img style={{ width: "100%", maxHeight: 350 }} alt="Portfolios" src="/img/validationRequired.svg" />
                          : <img style={{ width: "100%", maxHeight: 350 }} alt="Portfolios" src="/img/validationSent.svg" />}
                      </Box>
                      <Box sx={{ pt: 1 }}>
                        <Typography variant='body1' sx={{ fontWeight: 300 }}>{
                          !this.state.sent ?
                            <Trans components={{ br: <br /> }}>reg_validation_body</Trans>
                            : <Trans components={{ br: <br /> }}>reg_resent_body</Trans>
                        }</Typography>
                      </Box>
                      <Box sx={{ pt: 2 }}>
                        {!this.state.sent && <LoadingButton loading={isLoading} variant="contained" color="primary" size='large' loadingPosition="start" startIcon={<Send />} disableElevation onClick={this.resend}>{t("reg_validation_button")}</LoadingButton>}
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </Container>
            </MobileView>
          }
        </div>
      </Fade>
    );
  }
}

export default connect()(Identify);