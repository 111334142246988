import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { Close, LockOutlined } from '@mui/icons-material';
import { IconButton, Alert, Box, Collapse, Fade, Typography, InputAdornment } from '@mui/material';
import { withTheme } from '@emotion/react';
import { NavLink, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import api from '../services/api';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PasswordCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            open: false,
            token: null,
            password: null,
            errorOpen: false,
            errorMsg: '',
            done: false,
        }
    }

    componentDidMount(){
        let { token } = this.props.params;
        this.setState({token})
    }

    handleSubmit() {
        this.setState({ isLoading: true, errorOpen: false });
        let payload = { new_pwd: this.state.password, token: this.state.token }
        api.post('resetpwd3', payload)
            .then(response => {
                const data = response.data
                this.setState({ data, isLoading: false });

                if (data.result === 'OK') {
                    this.setState({ done: true })
                } else {
                    this.setState({ errorOpen: true, errorMsg: data.msg })
                }
            }).catch(error => {
                console.log(error);
                this.setState({ isLoading: false });
            })
    }

    handleChange(type, value) {
        this.setState({ [type]: value })
    }

    render() {
        const { isLoading, errorOpen, errorMsg, done } = this.state;
        return (
            <Fade in={true} timeout={450}>
                <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                    <Card variant="outlined" sx={{ width: '100%' }}>
                        <CardContent sx={{ padding: '32px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                                    <img style={{ margin: '16px' }} height={80} alt="Logo" src="/LogoColVert.svg" />
                                </Box>
                                <Box sx={{ pb: 4 }}>
                                    <Box sx={{ flex: '100%' }}>
                                        <Typography variant='h4' className='big'><Trans>createpwdTitle</Trans></Typography>
                                        <Typography variant='caption' color={'secondary'}><Trans>change_pwd_text</Trans></Typography>
                                    </Box>
                                </Box>
                                {!done ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, width: '100%' }}>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                        <TextField
                                                type="password"
                                                label="Password"
                                                variant="outlined"
                                                onChange={e => this.handleChange('password', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockOutlined />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <Collapse timeout={150} in={errorOpen}>
                                            <Alert
                                                severity="warning"
                                                color='error'
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            this.setState({ errorOpen: false })
                                                        }}><Close fontSize="inherit" /></IconButton>
                                                }
                                                sx={{ mb: 2 }}>

                                                {errorMsg}
                                            </Alert>
                                        </Collapse>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <LoadingButton loading={isLoading} loadingPosition="center" startIcon={""} size="large" onClick={() => this.handleSubmit()} variant="contained" color="primary"><Trans>change_pwd_btn</Trans></LoadingButton>
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ flex: '50%', textAlign: 'left', pl:1 }}><Typography variant='caption'><NavLink to='/'>Login</NavLink></Typography></Box>
                                            <Box sx={{ flex: '50%', textAlign: 'right', pr:1 }}><Typography variant='caption'><NavLink to='/signup'>Signup</NavLink></Typography></Box>
                                            </Box>
                                        </FormControl>
                                    </Box>
                                    :
                                    <Box>
                                        <Alert variant='standard' color='success'
                                        action={
                                            <Button 
                                            component={NavLink}
                                            to='/'
                                            variant='contained' 
                                            color="primary" 
                                            size="small">
                                              Go to login
                                            </Button>
                                          }>
                                            <Typography variant="subtitle1">
                                                The password has been changed.
                                            </Typography>
                                        </Alert>
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Fade>
        )
    }
}
export default withTheme(withParams(PasswordCreate));