import { EditOutlined, HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Chart from '../elements/Chart';
import DialogHelp from '../elements/DialogHelp';
import SkeletonChart from '../elements/SkeletonChart';
import api from '../services/api';

class MyStatsEquityLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingBackround: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      chart_options: 0,
      chart_type: "",
      chart_height: "auto",
      chart_series: 0,
      disabled: true,
      amountDialogOpen: false,
      amount: 0,

      selected: 10000
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  componentDidMount() {
    this.loadChart()
  }

  loadChart(value) {
    let selected = this.state.selected
    value = value ? value : selected
    this.setState({ selected: value })
    if (!value) this.setState({ isLoading: true })
    else this.setState({ isLoadingBackround: true })
    
    api.get('v2/myst/wa/eqline/' + value + '/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        var item, series = [];
        for (var i = 0; i < data.length; i++) {
          item = data[i];
          var quote = {};
          quote.x = item.exit_date//moment(item.exit_date).format("MMM YYYY");
          quote.y = item.eq;
          series.push(quote);
        }
        var series_data = [
          {
            name: t('myst_eqline_chart_label'),
            data: series
          }
        ];

        var options = {
          series: series_data,
          chart: {
            type: 'area',
            height: 350,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: ['#8D21E9'],
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          grid: {
            borderColor: '#e6e6e6',
            yaxis: {
              lines: {
                show: true,
              }
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2.5,
            curve: 'smooth',
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 5
            }
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0,000.0[0]') + '&euro;';
              }
            }
          },
          yaxis: {
            min: function (min) { return min },
            tickAmount: 3,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0,000.[00]') },
              style: {
                colors: ['#484848'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: ['#484848'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
        };
        this.setState({ isLoading: false, data, chart_options: options, chart_type: options.chart.type, chart_height: options.chart.height, chart_series: series_data, amount: localStorage.getItem('INVUNIT') });

      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(()=>{
        this.setState({ isLoading: false, isLoadingBackround: false });
      })
  }

  changeValue() {
    
    api.put('users/settings/investment_unit', {name:"investment_unit", value: parseFloat(this.state.amount) })
      .then(response => {
        const data = response.data
        localStorage.setItem('INVUNIT', parseFloat(this.state.amount));
        this.setState({ disabled: 1, amountDialogOpen: false });
        this.loadChart()
      }).catch(error => {
        console.log(error);
      })
  }

  handleAmountDialog() {
    this.setState(prevState => ({
      amountDialogOpen: !prevState.amountDialogOpen
    }));
  }


  enableButton(amount) {
    this.setState({ disabled: 0, amount })
  }

  render() {
    const { data, selected, disabled, amountDialogOpen, amount, isLoading, isLoadingBackround } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsEquityLineTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsEquityLineHelp
            </Trans>
          }
        />

        <Dialog open={amountDialogOpen} onClose={() => this.handleAmountDialog()}>
          <DialogContent>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label='Euro'
                defaultValue={amount}
                size='medium'
                onKeyUp={(e) => this.enableButton(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditOutlined />
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: 2 }} />
              <Button color="primary" disabled={disabled ? 1 : 0} onClick={() => this.changeValue()}>Apply</Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Card variant="outlined">
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MyStatsEquityLineTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ minHeight: 444 }}>
            {
              isLoading ? <SkeletonChart height={this.state.chart_height} /> :

                data.length > 0 ? (
                  <>
                    <Typography variant='caption' color={'primary'}>
                      <Trans
                        i18nKey='myst_eqline_chartTitle'
                        values={{ amount }}
                        components={{
                          linkToPopup_amount: <Button sx={{ ml: 3 }} variant='outlined' size='small' onClick={() => this.handleAmountDialog()}></Button>
                        }} />
                    </Typography>
                    <Chart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type}></Chart>
                  </>
                ) : <Empty />
            }
          </CardContent>
          <CardActions>
            {[
              { 'l': <Trans>myst_eqline_all_btn</Trans>, 'v': 10000 },
              { 'l': <Trans>myst_eqline_12m_btn</Trans>, 'v': 12 },
              { 'l': <Trans>myst_eqline_6m_btn</Trans>, 'v': 6 },
              { 'l': <Trans>myst_eqline_3m_btn</Trans>, 'v': 3 },
            ].map((item) => {
              const checked = selected === item.v;
              return (
                <Chip
                  sx={{ width: "100%", maxWidth: 92 }}
                  size='small'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={checked && isLoadingBackround ? "Loading..." : item.l}
                  onClick={() => {
                    this.loadChart(item.v);
                  }}
                />
              );
            })}
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default MyStatsEquityLine;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img src={'/img/empty/MyStatsEquityLineEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans>{t('myst_eqline_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_eqline_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 2 }}><Button component={NavLink} to='/mybraino' variant='outlined'><Trans>{t('myst_eqline_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}