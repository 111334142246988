import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class Chart extends Component {
  render() {
    let options = this.props.options ? this.props.options : null
    let series = this.props.series ? this.props.series : []
    let type = this.props.type ? this.props.type : 'line'
    let height = this.props.height ? this.props.height : 0

    return (
      <ReactApexChart
      options={options}
      series={series}
      type={type}
      height={height} />
    );

  }
}
export default Chart;
