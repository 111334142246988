import { BookmarkBorderOutlined, DeleteOutline, HelpOutline, NotificationsActive, NotificationsActiveOutlined, NotificationsOffOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Slide, Typography } from '@mui/material';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import { getFlag } from '../commons/Utils';
import DialogHelp from '../elements/DialogHelp';
import DialogSearch from '../elements/DialogSearch';
import SkeletonList from '../elements/SkeletonListAvatar';
import api from '../services/api';

const url = ''

class MyFavorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      helpOpen: false,
      searchOpen: false,
      data: [],
      isDialogOpen: false,
      neededReload: this.props.neededReload,

      snackOpen: false,
      snackMessage: '',
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    //this.handleClickOpen = this.handleDialogClose.bind(this);
  }

  loadFavorites() {
    this.setState({ isLoading: true });
    
    api.get('trading/favorites/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ data: data, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
  }

  reloadFavorites() {
    this.loadFavorites()
    this.props.notifyAdvicesChange()
  }

  deleteFav(id) {
    
    api.delete('favorite/' + localStorage.getItem('ID') + '/' + id)
      .then(response => {
        this.loadFavorites()
        this.props.notifyAdvicesChange()
        this.setState({ snackOpen: true, snackMessage: t('MyFavorites_removed_text') })
      }).catch(error => {
        console.log(error);
      })
  }

  handleOpen() {
    this.setState({
      isDialogOpen: true
    });
  }
  handleClose() {
    this.setState({
      isDialogOpen: false
    });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  closeSearchDialog() {
    this.setState({ searchOpen: false })
  }

  openSearchDialog() {
    this.setState({ searchOpen: true })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.neededReload && this.props.neededReload) {
      this.loadFavorites()
    }
  }

  async componentDidMount() {
    this.loadFavorites()
  }

  render() {
    const { data, isLoading, searchOpen, snackOpen, snackMessage } = this.state;
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
    return (
      <>
        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyFavoritesTitle")}
          helpDialogContent={<Trans
            components={{
              em: <em />, i: <i />,
              bookmark_border: <BookmarkBorderOutlined sx={{ verticalAlign: 'middle' }} />,
              notifications_active: <NotificationsActiveOutlined sx={{ verticalAlign: 'middle' }} />,
              delete: <DeleteOutline sx={{ verticalAlign: 'middle' }} />,
            }}>{"MyFavoritesHelp"}</Trans>
          }
          okButton="ok" />

        <DialogSearch
          searchOpen={searchOpen}
          closeSearch={() => this.closeSearchDialog()}
          reloadFavorites={() => this.reloadFavorites()}
        />

        <Card variant="outlined">
          <CardHeader
            title={<Typography variant="overline">{t("MyFavoritesTitle")}</Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutline />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ height: 300, overflow: 'auto' }}>
            <List >
              {data.length > 0 ? data.map((obj) => (
                <Box key={obj.id_instrument}>
                  <ListItem button component={NavLink} to={"/detail/" + obj.id_instrument}>
                    <ListItemAvatar><Avatar sx={{ width: 30, height: 30 }} alt={obj.inst_name} src={getFlag(obj.country_name)} /></ListItemAvatar>
                    <ListItemText
                      primary={<Typography variant="subtitle2" component={"div"}>{obj.inst_name}</Typography>}
                      secondary={obj.today_alert == 1 ?<Typography component={"div"}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <NotificationsActive sx={{ mr: 1, fontSize: 20 }} color='primary' />
                          <Typography variant='caption' component={"div"}>{t("MyFavorites_signal_text")}</Typography>
                        </Box></Typography> :
                        <Typography component={"div"}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <NotificationsOffOutlined sx={{ mr: 1, fontSize: 20 }} color='secondary' />
                          <Typography variant='caption' component={"div"}>{t("MyFavorites_nosignal_text")}</Typography>
                        </Box></Typography>
                      } />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="settings" onClick={() => this.deleteFav(obj.id_instrument)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider></Divider>
                </Box>
              )) :
                isLoading ? <SkeletonList></SkeletonList> : <Empty title={'titolo'} comment={'testo1'} />
              }
            </List>

          </CardContent>
          <CardActions >
            <Button variant='outlined' color={'primary'} onClick={() => this.openSearchDialog()}>Add favorites</Button>
          </CardActions>
        </Card>
      </>
    );
  }
}

export default MyFavorites;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img src={'/img/empty/MyFavoritesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='subtitle2'><Trans>{t('MyFavorites_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('MyFavorites_empty')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}