import { Box, Button, Container, Fade, Toolbar, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Component } from 'react';
import { BrowserView, MobileView, isBrowser, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { setMainTitle } from '../reduxActions/Actions';

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} params={useParams()} />;
}

class PaymentCompleted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentType: 0,
        }
    }

    componentDidMount() {
        this.props.dispatch(setMainTitle(t("paypal_returnTitle")))
        let { paymentType } = this.props.params;
        this.setState({ paymentType })
    }

    render() {
        return (
            <Fade in={true} timeout={450}>
                <div>
                    <Toolbar />
                    {isBrowser ?
                        <BrowserView>
                            <Container sx={{ p: 2 }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography variant='h3' gutterBottom>{t("paypal_returnTitle")}</Typography>
                                    {this.state.paymentType === "stripe" ?
                                        <div><Typography variant='h6' sx={{ fontWeight: 300 }}>{t("stripe_return_subtitle1")}</Typography></div>
                                        : this.state.paymentType === "paypal" ?
                                            <div><Typography variant='h6' sx={{ fontWeight: 300 }}>{t("paypal_return_subtitle1")}</Typography></div>
                                            : "Niente"
                                    }
                                </Box>
                                <Box sx={{ textAlign: "center", py: 2 }}>
                                    <img src="img/ecommerce/paymentSucces.svg" style={{ maxHeight: 400, maxWidth: "100%" }} />
                                </Box>
                                <Box sx={{ textAlign: "center", py: 2 }}>
                                    <Button component={NavLink} to={"/home"} variant='contained' size='large' disableElevation>{t("ecommerceBackButton")}</Button>
                                </Box>
                            </Container>
                        </BrowserView>
                        :
                        <MobileView>
                            <Container sx={{ p: 2, display: "flex", flexDirection: "column", height: "calc(100vh - 56px)", overflow: "hidden" }}>
                                <Box sx={{}}>
                                    <Typography variant='h4'>{t("paypal_returnTitle")}</Typography>
                                    {this.state.paymentType === "paypal" ?
                                        <div><Typography variant='body1' sx={{ fontWeight: 300 }}>{t("stripe_return_subtitle1")}</Typography></div>
                                        : this.state.paymentType === "stripe" ?
                                            <div><Typography variant='body1' sx={{ fontWeight: 300 }}>{t("paypal_return_subtitle1")}</Typography></div>
                                            : "Niente"
                                    }
                                </Box>
                                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                                    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                                        <img src="img/ecommerce/paymentSucces.svg" alt="Payment success" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "contain" }} />
                                    </Box>
                                </Box>
                                <Box sx={{ height: 75, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                                    <Button component={NavLink} to={"/home"} variant='contained' size='large' disableElevation>{t("ecommerceBackButton")}</Button>
                                </Box>
                            </Container>
                        </MobileView>
                    }
                </div>
            </Fade>
        );
    }
}

export default withParams(connect()(PaymentCompleted));