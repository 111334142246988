import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, ListItem, ListItemAvatar, ListItemText, List, Divider, ListItemSecondaryAction, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { AssignmentTurnedInOutlined, Cancel, CancelOutlined, CreditCardOutlined, EmailOutlined, EventOutlined, HistoryOutlined, ReceiptOutlined } from '@mui/icons-material'
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import { Link, NavLink } from 'react-router-dom';
import api from '../services/api';

class AccountMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <Trans> {this.props.prefix} </Trans>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      lang: localStorage.getItem('LANG'),
      notify1: false,
      notify2: false,
      isLight: true,
      confirmOpen: false,
      snackOpen: false,
      snackMessage: '',
      loading: false,
      subDeleted: false,
      error: null,
    }
  }

  async componentDidMount() {
    this.loadInfo()
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  loadInfo() {
    api.get('subscription_info/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ data })
      }).catch(error => {
        console.log(error);
      })
  }

  cancelSubscription() {
    console.log('cenceling...');
    this.setState({ confirmOpen: true })
  }

  cancelSubscription() {
    this.setState({ loading: true })
    api.delete('shop/subscription/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ subDeleted: true })
        console.log("deleted", data);
        this.setState({ error: null, confirmOpen: false, snackOpen: true, snackMessage: t('profile_cancel_subscription_canceled') })
        setTimeout(() => {
          this.loadInfo()
        }, 1500);
      }).catch(error => {
        console.log("not deleted", error);
        this.setState({ error: error.message })
      }).finally(() => {
        this.setState({ loading: false })
      })
  }

  openConfirm() {
    this.setState({ confirmOpen: true })
  }

  closeConfirm() {
    this.setState({ subDeleted: false })
    this.setState({ confirmOpen: false })
  }

  render() {
    const { data, snackOpen, snackMessage, confirmOpen, loading, error } = this.state;
    let isExpired = 0
    // Get the current date and time
    const currentDate = new Date();
    // Convert the given string to a Date object
    const dateString = data.next_payment;
    const givenDate = new Date(dateString);
    // Compare the given date with the current date
    if (data.next_payment && givenDate <= currentDate) {
      isExpired = 1
    }
    return (
      <>
        <Dialog open={confirmOpen} onClose={() => this.closeConfirm()}>
          <DialogTitle>{t('profile_cancel_subscription_title')}</DialogTitle>
          <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '50%' }}>
              <Typography>
                {t('profile_cancel_subscription_warning')}
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <img src='img/ecommerce/subCanceled.svg' />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box>
                <Typography color={'error'} variant='caption'>{error}</Typography>
              </Box>
              <Box>
                <Button onClick={() => this.closeConfirm()} disabled={loading ? true : false}>{t('profile_cancel_subscription_close')}</Button>
                <Button color='error' disabled={loading ? true : false}
                  onClick={() => this.cancelSubscription()}>
                  {loading ? t('profile_cancel_subscription_canceling') : t('profile_cancel_subscription_cancel')}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>

        <Card variant="outlined">
          <DialogHelp
            closeHelpDialog={() => this.closeHelpDialog()}
            isHelpDialogOpen={this.state.helpOpen}
            helpDialogTitle={this.state.helpTitle}
            helpDialogContent={this.state.helpText}
            okButton="ok" />

          <BrainoSnackbar
            open={snackOpen}
            onClose={() => { this.setState({ snackOpen: false }) }}
            message={snackMessage}
          />

          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2">info</Typography>}
          ></CardHeader>
          <CardContent>
            <List>
              <ListItem>
                <ListItemAvatar><AssignmentTurnedInOutlined /></ListItemAvatar>
                <ListItemText primary={t('profile_account_label')} secondary={data.account_level}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar><CreditCardOutlined /></ListItemAvatar>
                <ListItemText
                  primary={t('profile_payment_label')}
                  secondary={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {data.payment_type ? data.payment_type : '-'}
                      {data.status && data.payment_type === "Credit card" ? <Typography component={NavLink} to={"/editCard"} variant='body2' sx={{ ml: 1 }}> <Trans>profile_edit_card</Trans> </Typography> : ''}
                    </Box>
                  } />
              </ListItem>
              <ListItem>
                <ListItemAvatar><HistoryOutlined /></ListItemAvatar>
                <ListItemText primary={t('profile_last_payment_label')} secondary={data.last_payment ? data.last_payment : '-'}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar><EventOutlined /></ListItemAvatar>
                <ListItemText primary={data.status ? t('profile_next_payment_label') : isExpired ? t('profile_expired_label') : t('profile_expiration_label')}
                  secondary={data.next_payment ? data.next_payment : '-'}>

                </ListItemText>
              </ListItem>
              {data.status ?
                <ListItem>
                  <ListItemAvatar><CancelOutlined color='error' /></ListItemAvatar>
                  <ListItemText
                    primary={t('profile_cancel_subscription_label')}
                    secondary={
                      <Link to="#" onClick={() => this.openConfirm()} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography color="error" variant="body2">{t('profile_cancel_subscription_label_2')}</Typography>
                      </Link>
                    }
                  />
                </ListItem>
                : ''
              }
              <ListItem>
                <ListItemAvatar><ReceiptOutlined /></ListItemAvatar>
                <ListItemText primary={t('profile_invoices_label')} secondary={<NavLink to='/invoices'>{t('profile_invoices_link_label')}</NavLink>}></ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemAvatar><EmailOutlined /></ListItemAvatar>
                <ListItemText primary='email' secondary={data.email}></ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(AccountMain);
