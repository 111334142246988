import { DragIndicator } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { t } from 'i18next';
import React, { Component } from 'react';
import { DraggableCore } from 'react-draggable';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getFlag } from '../../commons/Utils';
import SkeletonList from '../../elements/SkeletonList';
import SkeletonListAvatar from '../../elements/SkeletonListAvatar';
import { DragElement } from '../../commons/DragElement';
import api from '../../services/api';

class MobMzAllocationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: -1,
      allowed: true,
      last: '',
      collapsed: true,
      yStart: window.innerHeight / 2 + 16, // initial position of draggable component
      yPos: window.innerHeight / 2 + 16,
      yMax: 0,
      yDir: 0,
    }

    this.draggableRef = React.createRef();
    this.parentRef = React.createRef();
    this.handleClose = this.handleStop.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    
    api.get('v2/mz/wa/composition_details/' + this.props.id)
      .then(response => {
        const data = response.data.list
        const last = response.data.last_update
        this.setState({ data });
        this.setState({ last });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        if (401 === error.response.status) {
          this.fetchLiteData()
        } else {
          console.log(error);
        }
      })
    this.setState({ isLoading: false });
  }

  fetchLiteData(){
    this.setState({ isLoading: true })
    
    api.get('v2/mz/wa/composition_details_lite/' + this.props.id)
      .then(response => {
        const data = response.data.list
        const last = response.data.last_update
        this.setState({ data });
        this.setState({ last });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false })
        if (401 === error.response.status) {
          this.setState({ allowed: false })
        } else {
          console.log(error);
        }
      })
    this.setState({ isLoading: false });
  }


  toggleCollapse = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  handleDrag = (event, ui) => {
    const { yStart } = this.state;
    const newY = ui.y;
    const parentTop = this.draggableRef.current.parentNode.getBoundingClientRect().top - 56
    if (newY <= parentTop) {
      this.setState({
        yPos: parentTop,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: false,
      });
    } else if (newY < yStart) {
      this.setState({
        yPos: newY,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    } else {
      this.setState({
        yPos: yStart,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    }
  }

  handleStop = (event, ui) => {
    const { yDir, yStart, yMax } = this.state
    if (yDir > 5) {
      this.setState({
        yPos: yStart,
        collapsed: true,
      })
    } else if (yDir < 5) {
      this.setState({
        yPos: yMax,
        collapsed: false,
      })
    }
  }

  render() {
    const { data, isLoading, collapsed, yPos, allowed } = this.state;
    const bg = "white"
    return (
      <DraggableCore
        axis="y" handle=".handle" onDrag={this.handleDrag} onStop={this.handleStop}
      >
        <Box ref={this.draggableRef} style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          cursor: 'move',
          border: '1px solid #e6e6e6',
          borderBottom: 0,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8, background: bg, maxHeight: '100%', top: yPos, transition: "all 300ms cubic-bezier(.22,.68,0,1.4)"
        }}>
          <Box className='handle' sx={{
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            background: bg,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 2,
          }}>
            <DragElement />
          </Box>
          <Box sx={{ px: 2 }}>
            <Typography gutterBottom variant="overline" component="h2">
              <b><Trans>MzAllocationTitle</Trans></b>
            </Typography>
          </Box>
          <Box sx={{ px: 2, background: bg, maxHeight: "calc(100% - 64px)", overflow: collapsed ? "hidden" : "auto", }}>
            {isLoading ? <SkeletonListAvatar rows={5} /> :
              <List>
                {data && data.length > 0 ? data.map((obj) => (
                  <div>
                    <ListItem key={obj.instrument_name}>
                      <ListItemAvatar>
                        <Avatar alt={obj.country_name} src={getFlag(obj.country_name)} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<b>{obj.ticker}</b>}
                        secondary={obj.instrument_name} />
                      <ListItemSecondaryAction><b>{obj.weight_val}</b></ListItemSecondaryAction>
                    </ListItem>
                  </div>
                )) :
                  allowed ? "no data"
                    :
                    <Unauthorized />
                }
              </List>
            }
          </Box>
        </Box>
      </DraggableCore>
    )
  }
}

export default MobMzAllocationDetails;

// ---------------------
// UNAUTHORIZED CONTENT
// ---------------------
class Unauthorized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', m: 1, py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%' }}><img src={'/img/401/Upgrade401.svg'} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {/* <Box><Typography gutterBottom variant='subtitle2'><b>{t('MzAllocationDetails401Title')}</b></Typography></Box> */}
            <Box sx={{ pb: 2 }}><Typography variant='body2'>{t('MzAllocationDetails401Text')}</Typography></Box>
            <Button disableElevation size='large' variant="contained" component={NavLink} to={'/plans'}>{t('MzAllocationDetails401Button')}</Button>
          </Box>
        </Box>
      </>
    )
  }
}