import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemSecondaryAction, ListItemText, Box } from '@mui/material';
import SkeletonList from '../../elements/SkeletonListAvatar';
import I18n from '../../commons/Utils';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import numeral from 'numeral';
import api from '../../services/api';

class MobMzRiskAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>
    }
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  componentDidMount() {
    
    api.get('v2/mz/wa/risk_details/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    return (
      <Box sx={{ p: 2 }}>
        <Typography gutterBottom variant="overline" component="h2">
          <b><Trans>MzRiskAnalysisTitle</Trans></b>
        </Typography>
        {data ? (
          <List dense>
            <ListItem>
              <ListItemText primary={t('mz_risk_label_beta')} />
              <ListItemSecondaryAction><Typography variant="subtitle2"><b>{data.beta}</b></Typography></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={t('mz_risk_label_sharpe')} />
              <ListItemSecondaryAction><Typography variant="subtitle2"><b>{data.sharpe}</b></Typography></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={t('mz_risk_label_volatility')} />
              <ListItemSecondaryAction><Typography variant="subtitle2"><b>{data.volatility}</b></Typography></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={t('mz_risk_label_sortino')} />
              <ListItemSecondaryAction><Typography variant="subtitle2"><b>{data.sortino}</b></Typography></ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={t('mz_risk_label_var_95')} />
              <ListItemSecondaryAction><Typography variant="subtitle2"><b>{data.var_95}</b></Typography></ListItemSecondaryAction>
            </ListItem>
          </List>
        ) :
          <SkeletonList></SkeletonList>
        }
      </Box>
    );
  }
}

export default MobMzRiskAnalysis;
