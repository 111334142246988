import React, { Component } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import SettingsMain from '../cards/SettingsMain';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

class MySettings extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      lang: localStorage.getItem('LANG')
    }
  }

  componentDidMount(){
  }

  render() {
    return (
      <Container sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h4" className='big'><Trans>{this.constructor.name + "Title"}</Trans></Typography>
        </Grid>
        <Grid item xs={12}>
          <SettingsMain />
        </Grid>
      </Grid>
    </Container>
    );
  }
}

export default withTranslation()(MySettings);