import { Bookmark, BookmarkBorder, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, Collapse, IconButton, Paper, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React, { Component } from 'react';
import SkeletonChart from '../../elements/SkeletonChart';
import SkeletonGauge from '../../elements/SkeletonGauge';
import SkeletonList2 from '../../elements/SkeletonList2';
import BrainoSnackbar from '../../commons/BrainoSnackbar';
import api from '../../services/api';

class MobDetailsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      descriptionOpen: false,
      bookmarked: false,
      snackOpen: false,
      snackMessage: '',
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    
    api.get('snapshot/' + localStorage.getItem('ID') + '/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data, bookmarked: data.isFav });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  toggleFav() {
    

    if (this.state.bookmarked) {
      api.delete('favorite/' + localStorage.getItem('ID') + '/' + this.props.id)
        .then(response => {
          this.setState({ bookmarked: !this.state.bookmarked, snackOpen: true, snackMessage: !this.state.bookmarked === true ? t('MyFavorites_added_text') : t('MyFavorites_removed_text') });
        }).catch(error => {
          console.log(error);
        })
    } else {
      api.post('favorite/' + localStorage.getItem('ID') + '/' + this.props.id, '')
        .then(response => {
          this.setState({ bookmarked: !this.state.bookmarked, snackOpen: true, snackMessage: !this.state.bookmarked === true ? t('MyFavorites_added_text') : t('MyFavorites_removed_text') });
        }).catch(error => {
          console.log(error);
        })
    }
  }

  render() {
    const { data, descriptionOpen, bookmarked, isLoading, snackMessage, snackOpen } = this.state;
    return (
      <>
        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        {data ? (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'start', py: 2 }}>
              <Box sx={{ flex: '70%', pr: 2, display: 'flex', flexDirection: "column" }}>
                <Typography className='big' variant="h4">{data.n}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 1 }} className='big' variant="body1" color={''}>{data.t + '.' + data.mc}</Typography>
                  <IconButton
                    onClick={() => {
                      this.toggleFav();
                    }}
                  >
                    <Tooltip title={bookmarked ? 'Remove from favorites' : 'Add to favorites'} arrow>
                      {bookmarked ? <Bookmark sx={{ fontSize: 20 }} color='primary' /> : <BookmarkBorder sx={{ fontSize: 20 }} color='primary' />}
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ flex: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2 }}>
                {
                  isLoading ? <SkeletonChart /> :
                    data.logo ?
                      <img src={"https://eodhistoricaldata.com" + data.logo} style={{ maxWidth: "100%", maxHeight: 80 }} />
                      : "-"
                }
              </Box>
            </Box>
            <Box><Typography variant='overline'>Info</Typography></Box>
            <Collapse collapsedSize={40} in={descriptionOpen} sx={{ mt: 1 }}>
              <Typography gutterBottom variant="body2" component="div" className='slim' sx={{lineHeight: "inherit"}}>
                {data.description}
              </Typography>
            </Collapse>
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              <Tooltip title={descriptionOpen ? 'Reduce info' : 'Expand info'} arrow>
                <Chip
                  variant='outlined'
                  avatar={descriptionOpen ? <ExpandLess /> : <ExpandMore />}
                  label={descriptionOpen ? 'Show less' : 'Show more'}
                  size='medium'
                  onClick={() => this.toggleDescription()} />
              </Tooltip>
            </Box>

            <Box className="noBars" sx={{ mt: 2, display: 'flex', overflowX: 'auto', width: '100%', maxWidth: "calc(100vw - 32px)" }}>
              {[
                { "val": data.mn, "label": "Market" },
                { "val": data.isin || '-', "label": "ISIN" },
                { "val": data.sn, "label": "Sector" },
                { "val": data.ind, "label": "Industry" },
              ].map((obj, index) => (
                <Paper sx={{ p: 1, mr: 1, width: "100%" }}>
                  <Typography variant="subtitle2" component="div" sx={{ whiteSpace: "nowrap", minWidth: 150, }}>{obj.val}</Typography>
                  <Typography variant="caption" component="h2" color="secondary">{obj.label}</Typography>
                </Paper>
              ))}
            </Box>


          </div>)
          : isLoading ?
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'start', py: 2 }}>
                <Box sx={{ flex: '70%', display: 'flex', flexDirection: "column" }}><SkeletonList2 rows={1} /></Box>
                <Box sx={{ flex: '30%', display: 'flex', justifyContent: "center" }}><SkeletonGauge width={60} height={60} /></Box>
              </Box>
              <Box>
                <Box sx={{ flex: '70%', display: 'flex', flexDirection: "column" }}><SkeletonChart /></Box>
              </Box>
            </Box>
            : 'no data'
        }
      </>
    );
  }
}

export default MobDetailsOverview;
