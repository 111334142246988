import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, IconButton, Button, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import SkeletonList from '../elements/SkeletonListAvatar';
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import MzRiskDetails from './MzRiskDetails';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import numeral from 'numeral';
import api from '../services/api';

class MzRiskAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>
    }
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  componentDidMount() {
    
    api.get('v2/mz/wa/risk_details/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    const { classes } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzRiskAnalysisTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzRiskAnalysisHelp
            </Trans>
          }
        />

        <MzRiskDetails prefix="mz_risk" isDialogOpened={this.state.detailsOpen} closeDialog={() => this.closeDetailsDialog()} id={this.props.id}></MzRiskDetails>
        
        <Card variant="outlined" className='fullHeightCard'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzRiskAnalysisTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            {data ? (
              <List>
                <ListItem>
                  <ListItemText primary={t('mz_risk_label_beta')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.beta}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_risk_label_sharpe')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.sharpe}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_risk_label_volatility')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.volatility}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_risk_label_sortino')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.sortino}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_risk_label_var_95')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.var_95}</Typography></ListItemSecondaryAction>
                </ListItem>
              </List>
            ) :
              <SkeletonList></SkeletonList>
            }
          </CardContent>
          <CardActions>
          <Button color="primary" onClick={() => this.openDetailsDialog()}><Trans>mz_risk_button</Trans></Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default MzRiskAnalysis;
