import React from 'react';
import { Dialog, DialogActions, Button, Typography, DialogContent, DialogTitle, Slide } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogHelp = props => (
  <div>
    <Dialog 
    fullWidth={props.fullWidth ? props.fullWidth : false } 
    fullScreen={props.fullScreen ? props.fullScreen : false }
    id={props.id} open={props.isHelpDialogOpen} onClose={props.closeHelpDialog} TransitionComponent={Transition}>
      <DialogTitle>{props.helpDialogTitle}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">{props.helpDialogContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='' onClick={props.closeHelpDialog} color="primary">
          <CheckOutlinedIcon color="primary"></CheckOutlinedIcon>
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default DialogHelp;