import { withTheme } from '@emotion/react';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Typography } from '@mui/material';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Chart from '../elements/Chart';
import DialogConfirm from '../elements/DialogConfirm';
import DialogHelp from '../elements/DialogHelp';
import SkeletonChart from '../elements/SkeletonChart';
import api from '../services/api';

class MyStatsBreakdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",

      selected: 'global'
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.loadHeatmap()
  }

  loadHeatmap(value) {
    let selected = this.state.selected
    value = value ? value : selected
    this.setState({ isLoading: true })

    
    api.get('v2/myst/wa/breakdown/' + value + '/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        var items = data.data;
        var sectors = data.sectors;
        var markets = data.markets;
        var series_data = [], ranges = [];

        var max = -999999;
        var min = 999999;

        if (items.length > 0) {
          for (var i = 0; i < markets.length; i++) {
            var block = [];
            block.name = markets[i].market_name;
            block.data = [];
            for (var x = 0; x < sectors.length; x++) {
              var sector_block = {};
              sector_block.x = sectors[x].sector_name;
              sector_block.y = null;
              for (var y = 0; y < items.length; y++) {
                if (items[y].market_name === block.name && items[y].sector_name === sectors[x].sector_name) {
                  sector_block.y = items[y].profit;
                }
                if (items[y].profit * 1 > max * 1) max = items[y].profit
                if (items[y].profit * 1 < min * 1) min = items[y].profit
              }
              block.data.push(sector_block);
            }
            series_data.push(block);
          }

          /* shades handling (bug in Apexcharts) */
          var loss = ['#ff8e00', '#ff991a', '#ffa533', '#ffb04d', '#ffbb66', '#ffc780', '#ffd299', '#ffddb3', '#ffe8cc', '#fff4e6'];
          var win = ['#33cd99', '#47d2a3', '#5cd7ad', '#70dcb8', '#85e1c2', '#99e6cc', '#adebd6', '#c2f0e0', '#d6f5eb', '#ebfaf5'];
          var index = 0;
          for (var i = min; i < 0; i -= min / 5) {
            var from = i * 1;
            var to = i * 1 - (min / 5 * 1);
            ranges.push({ from: from, to: to, color: loss[index] })
            index++;
          }

          index = 0;
          for (var i = 0; i < max; i += max / 5) {
            var from = i * 1;
            var to = i * 1 + (max / 5 * 1);
            ranges.push({ from: from, to: to, color: win[index] })
            index++;
          }
          ranges.push({ from: null, to: null, color: '#fefefe' })
          /* ----------------------------------- */
          var options = {
            legend: {
              show: false,
            },
            series: series_data,
            noData: {
              text: "LOADING..."
            },
            chart: {
              height: 500,
              type: 'heatmap',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              animations: {
                enabled: true,
                easing: 'easeout',
                speed: 550,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: false,
                  speed: 350
                }
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              colors: ['#fff'],
              width: 1,
            },
            plotOptions: {
              heatmap: {
                enableShades: false,
                shadeIntensity: 0.5,
                reverseNegativeShade: false,
                colorScale: {
                  ranges: ranges
                }
              }
            },
            tooltip: {
              enabled: true,
              y: {
                formatter: function (val, opts) {
                  return numeral(val).format('+0.00') + '%'
                },
              },
              marker: {
                show: false,
              },
            },
            yaxis: {
              show: true
            },
            xaxis: {
              show: true,
              labels: {
                rotate: -45,
                trim: true,
                hideOverlappingLabels: true,
                formatter: (value) => { return value; },
              },
            },
          };
          this.setState({ isLoading: false, data: items, chart_options: options, chart_type: options.chart.type, chart_height: options.chart.height, chart_series: series_data });
        } else {
          this.setState({ isLoading: false })
        }

      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
  }

  loadTreemap(value) {
    let selected = this.state.selected
    value = value ? value : selected
    this.setState({ isLoading: true })

    
    api.get('v2/myst/wa/breakdown/' + value + '/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        var series = [];

        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            var block = {};
            block.x = data[i].market_name ? data[i].market_name : data[i].sector_name;
            block.y = data[i].profit;
            series.push(block);
          }

          var series_data = [
            {
              name: ('myst_breakdown_chart_label'),
              data: series
            }
          ];

          var options = {
            series: series_data,
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#fff'],
                fontFamily: 'Roboto',
                fontWeight: 400
              },
            },
            legend: {
              show: false,
            },
            chart: {
              height: 500,
              type: 'treemap',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              animations: {
                enabled: true,
                easing: 'easeout',
                speed: 550,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: false,
                  speed: 350
                }
              },
            },
            colors: [this.props.theme.palette.chartPrimary],
            tooltip: {
              enabled: true,
              y: {
                formatter: function (val, opts) {
                  return numeral(val).format('+0.00') + '%'
                },
              },
              marker: {
                show: false,
              },
            },
          };
          this.setState({ isLoading: false, data, chart_options: options, chart_type: options.chart.type, chart_height: options.chart.height, chart_series: series_data });
        }
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  changeTimeFrame(tf) {
    this.setState({ selected: tf })

    if (tf === 'global') {
      this.loadHeatmap(tf)
    } else {
      this.loadTreemap(tf)
    }
  }

  render() {
    const { data, selected, posConfirmOpen, isLoading } = this.state;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsBreakdownTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsBreakdownHelp
            </Trans>
          }
          okButton="ok" />

        <DialogConfirm
          id={this.state.posConfirmTradeId}
          closeLoginDialog={() => this.closePosConfirm()}
          isLoginDialogOpen={posConfirmOpen}
          confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
          posDialogTitle="Aggiungi Posizione"
          posDialogContent="Vuoi aggiungere la posizione alla lista? Per ricevere notifiche sulle posizioni in chiusura è necessario abilitare l'opzione inviami notifiche, newsletter di informativa finanziaria e marketing nelle impostazioni."
          cancelButton="cancel"
          okButton="ok" />

        <Card variant="outlined" className='fullHeightCard'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MyStatsBreakdownTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ minHeight: 563 }}>
            {
              isLoading ? <SkeletonChart height={this.state.chart_height} /> :
                data.length > 0 ? (
                  <>
                    <Chart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type}></Chart>
                  </>
                ) : <Empty />
            }
          </CardContent>
          <CardActions>
            {[
              { 'l': t('myst_breakdown_all_btn'), 'v': 'global' },
              { 'l': t('myst_breakdown_sectors_btn'), 'v': 'sectors' },
              { 'l': t('myst_breakdown_markets_btn'), 'v': 'markets' },
            ].map((item) => {
              const checked = selected === item.v;
              return (
                <Chip
                  sx={{ width: "100%", maxWidth: 92 }}
                  size='small'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  onClick={() => this.changeTimeFrame(item.v)}
                />
              );
            })}
          </CardActions>
        </Card>
      </>
    );
  }
}

export default withTheme(MyStatsBreakdown);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ p: 2, flex: '50%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyStatsBreakdownEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 2, flex: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans>{t('myst_breakdown_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_breakdown_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 4 }}><Button component={NavLink} to='/mybraino' variant='outlined'><Trans>{t('myst_breakdown_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}