import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { withStyles } from "@mui/material";
import SkeletonList from '../elements/SkeletonListAvatar';
import { IconButton, CardHeader } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import DialogHelp from '../elements/DialogHelp';
import MzPerformancesDetails from './MzPerformancesDetails';
import I18n from '../commons/Utils';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import api from '../services/api';

class MzPerformances extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>
    }
  }

  async componentDidMount() {
    
    api.get('v2/mz/wa/report/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  render() {
    const { data, detailsOpen } = this.state;
    return (
      <div style={{ height: '100%' }}>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzPerformancesTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzPerformancesHelp
            </Trans>
          }
        />

        <MzPerformancesDetails prefix="mz_performance" isDialogOpened={detailsOpen} closeDialog={() => this.closeDetailsDialog()} id={this.props.id}></MzPerformancesDetails>

        <Card variant="outlined" sx={{ height: '100%' }}>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzPerformancesTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            {data ? (
              <List>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_inception')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.return_inception}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_1M')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.return_1m}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_90D')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.return_3m}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_1Y')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.return_1y}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_YTD')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.return_ytd}</Typography></ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('mz_performance_label_alpha')} />
                  <ListItemSecondaryAction><Typography variant="h6">{data.alpha}</Typography></ListItemSecondaryAction>
                </ListItem>
              </List>
            ) :
              <SkeletonList></SkeletonList>
            }
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => this.openDetailsDialog()}><Trans>mz_performance_button</Trans></Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withTheme(MzPerformances);
