import { withTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Trans } from 'react-i18next';
import { COLORS } from '../../commons/Colors';
import SkeletonGauge from '../../elements/SkeletonGauge';
import SkeletonScore from '../../elements/SkeletonScore';
import DialogHelp from '../../elements/DialogHelp';
import { IconButton } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import api from '../../services/api';


class MobDetailsScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
      helpOpen: false,
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  componentWillUnmount() {
    this.setState({ data: null });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    
    api.get('trading/strength/' + this.props.id)
      .then(response => {
        const data = response.data[0]
        this.props.updateScore(data.strength, data.open_signal, data.id_trade)
        let options = {
          series: [data ? data.strength : 0],
          chart: {
            height: '100%',
            type: 'radialBar',
            offsetY: 0
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "40%"
              },
              startAngle: -120,
              endAngle: 120,
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '11px',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  color: '#848484',
                  offsetY: 56
                },
                value: {
                  show: true,
                  fontSize: "25px",
                  fontFamily: 'Fjalla One',
                  color: '#484848',
                  offsetY: 24,
                  formatter: function (val) {
                    return val ? numeral(val).format('0.0') : '-';
                  }
                },
              },
              track: {
                background: '#e6e6e6',
                startAngle: -120,
                endAngle: 120,
              },
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          colors: [data && data.open_signal === 'B' ? this.props.theme.palette.buy.main : data && data.open_signal === 'B' ? this.props.theme.palette.sell.main : COLORS.lightgrey],
          fill: {
            type: 'solid',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50]
            },
          },
          labels: ['Brainopoints'],
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }
          },
        };
        this.setState({ data, options })

      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { data, options, isLoading } = this.state;
    const signal = data && data.open_signal === 'B' ? 'buy' : 'sell';

    return (
      <>
        <DialogHelp
          fullWidth={true}
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("DetailsScoreTitle")}
          helpDialogContent={<Trans components={{ em: <em></em> }}>{"DetailsScoreHelp"}</Trans>}
          okButton="ok" />

        <Box sx={{ mt: 2 }}>
          {data ? (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ flex: "50%" }}>
                  <Typography variant='overline'>Braino Score</Typography>
                </Box>
                <Box sx={{ flex: "50%", textAlign: "right" }}>
                  <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                    <HelpOutline />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: 0, p: 0, alignItems: "center" }}>
                <Box sx={{ flex: '50%' }}>
                  {options ?
                    <ReactApexChart options={this.state.options} series={this.state.options.series} type="radialBar" height={150} />
                    : isLoading ? <SkeletonGauge width={'100%'} height={150} /> : '-'}
                </Box>
                <Box sx={{ flex: '50%', p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: 0 }}>
                    <Box><Typography variant='body1'>
                      {data.strength ?
                        <>
                          <Trans
                            values={{ strength: numeral(data.strength).format('0.0'), signal }}>
                            {"DetailsScore_comment_" + data.score}
                          </Trans>
                        </>
                        : <Trans>DetailsScore_comment_empty</Trans>
                      }
                    </Typography></Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : isLoading ? <SkeletonScore /> : 'No signals today'}
        </Box>
      </>
    );
  }
}

export default withTheme(MobDetailsScore);
