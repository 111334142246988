import React, { Component } from 'react';
import { Typography, Chip, Box, Button, Grid } from '@mui/material';
import DialogHelp from '../../elements/DialogHelp';
import numeral from 'numeral';
import moment from 'moment';
import SkeletonChart from '../../elements/SkeletonChart';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import api from '../../services/api';

class MobMyStatsBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingBackround: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      chart_options: 0,
      chart_type: "",
      chart_height: "auto",
      chart_series: 0,

      selected: 10000
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.loadBalance()
  }

  loadBalance(tf) {
    if (!tf) this.setState({ isLoading: true })
    else this.setState({ isLoadingBackround: true })
    
    tf = tf ? tf : this.state.selected
    api.get('v2/myst/wa/balance/' + tf + '/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        var item, series = [];
        for (var i = 0; i < data.length; i++) {
          item = data[i];
          var quote = {};
          quote.x = moment(item.dt).format("MMM YYYY");
          quote.y = item.profit;
          series.push(quote);
        }
        var series_data = [
          {
            name: t('myst_balance_chart_label'),
            data: series
          }
        ];
        var options = {
          series: series_data,
          chart: {
            type: 'bar',
            height: 200,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: ['#33cd99'],
          grid: {
            borderColor: '#e6e6e6',
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              dataLabels: {
                position: 'top',
              },
              colors: {
                ranges: [
                  { from: -100, to: 0, color: '#ff8c00' },
                  { from: 0, color: '#33cd99' }
                ],
              },
            }
          },
          dataLabels: {
            enabled: false,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#484848']
            },
            formatter: function (val, opts) {
              return numeral(val).format('+0.00') + '%'
            },
          },
          stroke: {
            show: false,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            enabled: true,
            intersect: false,
            y: {
              formatter: function (val, opts) {
                return numeral(val).format('+0.00') + '%'
              },
            },
            x: {
              format: 'MMM YYYY',
            },
            marker: {
              show: false,
            },
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            forceNiceScale: true,
            tickAmount: 3,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[0]') + '%' },
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            //tickAmount: 3,
            tickPlacement: 'on',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: true
            },
          },
        };
        this.setState({ isLoading: false, data, chart_options: options, chart_type: options.chart.type, chart_height: options.chart.height, chart_series: series_data });

      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(()=>{
        this.setState({ isLoading: false, isLoadingBackround: false });
      })
  }

  changeTimeFrame(tf) {
    this.setState({ selected: tf })
    this.loadBalance(tf)
  }

  render() {
    const { data, posConfirmOpen, selected, isLoading, isLoadingBackround } = this.state;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>myst_balanceTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              {this.constructor.name + "Help"}
            </Trans>
          }
        />

        <Box sx={{ mb: 3 }}>
          <Typography gutterBottom variant="overline" component="h2"><b><Trans>myst_balanceTitle</Trans></b></Typography>
          <Box sx={{ mb: 2 }}>
            {
              isLoading ? <SkeletonChart height={215} /> :
                <>
                  {
                    data.length > 0 ? (
                      <>
                      <ReactApexChart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type} />
                      </>
                    ) : <Empty img={this.constructor.name} />
                  }
                </>
            }
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pt: 2 }}>
            <Grid container maxWidth={300} spacing={1}>
              {[
                { 'l': t("myst_balance_all_btn"), 'v': 10000 },
                { 'l': t("myst_balance_12m_btn"), 'v': 12 },
                { 'l': t("myst_balance_6m_btn"), 'v': 6 },
                { 'l': t("myst_balance_3m_btn"), 'v': 3 },
              ].map((item) => {
                const checked = selected === item.v;
                return (
                  <Grid item xs={6}>
                    <Chip
                      sx={{ width: "100%", }}
                      size='medium'
                      disabled={isLoading ? true : false}
                      key={item.l}
                      variant={checked ? 'plain' : 'outlined'}
                      color={checked ? 'primary' : 'secondary'}
                      label={checked && isLoadingBackround ? "Loading..." : item.l}
                      onClick={() => {
                        this.changeTimeFrame(item.v);
                      }}
                    >
                    </Chip>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

export default MobMyStatsBalance;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%' }}><img src={'/img/empty/MyStatsBalanceEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans><b>{t('myst_balance_emptyTitle')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_balance_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 2 }}><Button variant='contained' disableElevation component={NavLink} to='/mybraino'><Trans>{t('myst_balance_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}