export const setMainTitle = (value) => {
    return{
        type: "TITLE",
        value
    }
}

export const notifyLogin = () => {
    return {
        type: "LOGIN_STATUS",
        value: true
    }
}
export const notifyLogout = () => {
    return {
        type: "LOGIN_STATUS",
        value: false
    }
}
export const notifyLoginUpdates = () => {
    return {
        type: "LOGIN_STATUS_UPDATE",
        value:  1
    }
}
