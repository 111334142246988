import React, { Component } from 'react';
import { Box, CircularProgress } from "@mui/material";

class PageLoader extends Component {

  render() {
    return (
      <Box sx={{ position: 'absolute', zIndex: 999, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffffaa' }}>
        <CircularProgress />
      </Box>
    );
  }
}

export default PageLoader;
