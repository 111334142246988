import { withTheme } from '@emotion/react';
import { CheckOutlined, HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, CardHeader, Chip, IconButton } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Trans } from 'react-i18next';
import I18n from '../commons/Utils';
import DialogHelp from '../elements/DialogHelp';
import SkeletonGauge from '../elements/SkeletonGauge';
import MzAllocationDetails from './MzAllocationDetails';
import api from '../services/api';

class MzAllocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      sectors: [],
      isMarketClickable: true,
      marketColor: 'primary',
      isSectorClickable: false,
      sectorColor: 'default',
      helpOpen: false,
      detailsOpen: false,
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      options: null,

      selected: 'sector'
    }
  }

  getAllocation(type) {
    numeral.options.scalePercentBy100 = false
    type = type ? type : 'sector';
    this.setState({ selected: type, isLoading: true });
    
    api.get('v2/mz/wa/portfolio_allocation_' + type + '/' + this.props.id)
      .then(response => {
        const data = response.data
        var data_sectors = data.sectors;
        var item = {}, series = [], labels = [];
        for (var i = 0; i < data_sectors.length; i++) {
          series.push(parseFloat(data_sectors[i].allocation))
          labels.push(data_sectors[i].group_name)
        }

        var options = {
          series: series,
          labels: labels,
          chart: {
            type: 'pie',
            animations: {
              enabled: true,
              easing: 'easeout',
              speed: 450,
              animateGradually: {
                enabled: true,
                delay: 50
              },
              dynamicAnimation: {
                enabled: false,
              }
            },
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0.0[0]%');
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Roboto, sans-serif',
              colors: ['#fff']
            },
            dropShadow: {
              enabled: false
            }
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
              expandOnClick: true,
              offsetX: 0,
              offsetY: 0,
              customScale: 1,
              dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
              },
              donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                      return val
                    }
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 400,
                    offsetY: 16,
                    formatter: function (val) {
                      return numeral(val).format('0.00%')
                    }
                  },
                  total: {
                    show: false,
                    showAlways: false,
                    label: 'Total',
                    fontSize: '22px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 600,
                    color: '#373d3f',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                    }
                  }
                }
              },
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          theme: {
            monochrome: {
              enabled: true,
              color: this.props.theme.palette.chartPrimary,
              shadeTo: 'light',
              shadeIntensity: 0.6
            }
          }
        };
        this.setState({ data, options, isLoading: false });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
  }

  async componentDidMount() {
    this.getAllocation();
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  render() {
    const { data, isLoading, selected, detailsOpen, options } = this.state;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzAllocationTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzAllocationHelp
            </Trans>
          }
        />
        <Card variant="outlined" className='fullHeightCard'>

          <MzAllocationDetails isDialogOpened={detailsOpen} closeDialog={() => this.closeDetailsDialog()} id={this.props.id}></MzAllocationDetails>

          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzAllocationTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center' }}><SkeletonGauge style={{ margin: '0 auto' }} width={280} height={280} /></Box>
              : !isLoading && data ? (
                <ReactApexChart options={options} series={options.series} type='donut' height="300" />
              )
                : <div>no data</div>}
          </CardContent>
          <CardActions>
            <Box sx={{ flexGrow: 1 }}>
              {[
                { 'l': t('mz_portfAllocation_button_sectors'), 'v': 'sector' },
                { 'l': t('mz_portfAllocation_button_markets'), 'v': 'market' },
              ].map((item) => {
                const checked = selected === item.v;
                return (
                  <Chip
                    sx={{ mr: 1 }}
                    size='medium'
                    key={item.l}
                    variant={checked ? 'plain' : 'outlined'}
                    color={checked ? 'primary' : 'secondary'}
                    label={checked ? (<div><CheckOutlined sx={{ fontSize: 14, verticalAlign: 'middle' }} /> {item.l}</div>) : <div>{item.l}</div>}
                    onClick={() => {
                      this.getAllocation(item.v);
                    }}
                  >
                  </Chip>
                );
              })}
            </Box>
            <Button variant='outlined' color="primary" onClick={() => this.openDetailsDialog()}><Trans>mz_portfAllocation_button</Trans></Button>
          </CardActions>
        </Card>
      </>
    );
  }
}

export default withTheme(MzAllocation);
