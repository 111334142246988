const titleReducer = (state = 'Braino', action) => {
    switch(action.type){
        case "TITLE": {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export default titleReducer;