import React from "react";
import { Dialog, DialogActions, Button, Typography, DialogContent, DialogTitle } from '@mui/material';

const DialogConfirm = props => (
  <div>
    <Dialog id={props.id} open={props.isLoginDialogOpen} onClose={props.closeLoginDialog}>
      <DialogTitle>{props.posDialogTitle}</DialogTitle>
      <DialogContent>
      <Typography gutterBottom variant="body1">{props.posDialogContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeLoginDialog} color="primary">
          {props.cancelButton}
        </Button>
        <Button variant='contained' onClick={props.confirmTradeOpen} color="primary">
          {props.okButton}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default DialogConfirm;