import React, { Component } from 'react';
import { Container, Fade, Grid, Toolbar, Typography } from '@mui/material';
import SettingsMain from '../cards/SettingsMain';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { setMainTitle } from '../reduxActions/Actions';
import { connect } from 'react-redux';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import SettingsMainMob from '../cards/SettingsMainMob';

class MySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: localStorage.getItem('LANG')
    }
  }

  componentDidMount() {
    this.props.dispatch(setMainTitle("My Settings"))
  }

  render() {
    return (
      <div>
        {isBrowser ?
          <BrowserView>
            <Fade in={true} timeout={450}>
              <div>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" className='big'><Trans>settings_pageTitle</Trans></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SettingsMain />
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Fade>
          </BrowserView>
          :
          <MobileView>
            <Fade in={true} timeout={450}>
              <div>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <SettingsMainMob />
                </Container>
              </div>
            </Fade>
          </MobileView>
        }
      </div>
    );
  }
}

export default withTranslation()(connect()(MySettings));