import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WebAuth } from 'auth0-js';

/**
 * FIREBASE CONFIG
 */
const firebase_config = {
    apiKey: "AIzaSyCHuLjGYH-27WKR_1FesXTWLRja5vhemyc",
    authDomain: "cognitive-b7702.firebaseapp.com",
    databaseURL: "https://cognitive-b7702.firebaseio.com",
    projectId: "cognitive-b7702",
    storageBucket: "cognitive-b7702.appspot.com",
    messagingSenderId: "1099351382570"
};

firebase.initializeApp(firebase_config)
export const firebaseAuth = firebase.auth();

// GOOGLE LOGIN
const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })
provider.addScope('email')
export const getGoogleProvider = provider

// FACEBOOK LOGIN
const provider2 = new firebase.auth.FacebookAuthProvider()
provider2.setCustomParameters({ prompt: 'select_account' })
provider2.addScope('email')
export const getFacebookProvider = provider2

/////////////////////////////////////////////////////////////////////////////

/**
 * OAUTH0 CONFIG
 */
 const webAuth = new WebAuth({
	domain : 'braino.eu.auth0.com',
	clientID : 'vA4gFHU_VZczZrYRKkDvGS6YabmamPOj'
});
/*webAuth.parseHash({
	hash : window.location.hash
}, function(err, authResult) {
	if (err) {
		return console.log(err);
	}
	
	if(!authResult){
		return 0;
	}

	webAuth.client.userInfo(authResult.accessToken, function(err, user) {
		console.log('linkedin', user);
	});
});*/

// LINKEDIN LOGIN
export const signInWithLinkedin = () => webAuth.authorize({
    redirectUri : process.env.REACT_APP_AUTH0_REDIRECT_URI,
    responseType : 'token',
    scope : 'openid profile email',
    connection : 'linkedin'
}, function(err, authResult) {
    console.log(err, authResult)
});
