import React from "react";
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';

function SkeletonList() {
    return (
        <div>
            <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="10%" />
                </Box>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="10%" />
                </Box>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="10%" />
                </Box>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="10%" />
                </Box>
            </Box>
        </div>
    );
}

export default SkeletonList;