import React, { Component } from 'react';
import { Typography } from '@mui/material';
import { Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, Divider } from '@mui/material';
import {HelpOutlineOutlined as HelpOutlineOutlinedIcon} from '@mui/icons-material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import api from '../services/api';

class FullDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,

      anagraphic: [],
      performances: [],
      series: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.loadAnagraphic()
      this.loadPerformances()
      this.loadSeries()
    }
  }

  componentDidMount() {
    this.loadAnagraphic()
    this.loadPerformances()
    this.loadSeries()
}

  loadAnagraphic() {
    
    api.get('snapshot/' + localStorage.getItem('ID') + '/' + this.props.instrument + '/')
      .then(response => {
        this.setState({ anagraphic: response.data })
      }).catch(error => {
        console.log(error);
      })
  }

  loadSeries() {
    
    api.get('trading/winseries/' + localStorage.getItem('ID') + '/' + this.props.instrument + '/theta2/')
      .then(response => {
        this.setState({ series: response.data })
      }).catch(error => {
        console.log(error);
      })
  }

  loadPerformances() {
    
    api.get('trading/statistics/' + localStorage.getItem('ID') + '/' + this.props.instrument + '/0/theta2/')
      .then(response => {
        this.setState({ performances: response.data })
      }).catch(error => {
        console.log(error);
      })
  }

  handleClose() {
    this.props.close();
  }

  render() {
    const { anagraphic, performances, series } = this.state;
    const { open } = this.props;
    let instrumentName = anagraphic ? anagraphic.n : '-'
    let instrumentTicker = anagraphic ? anagraphic.t : '-'
    let instrumentLogo = anagraphic ? 'https://eodhistoricaldata.com' + anagraphic.logo : '/none.jpg'

    let best_series_count = series ? series.best_series_count : '-'
    let best_dates = series ? series.best_series_start + ' - ' + series.best_series_end : '-'
    let last_series_count = series ? series.last_series_count : '-'
    let last_dates = series ? series.last_series_start + ' - ' + series.last_series_end : '-'

    let avg_overall = performances ? numeral(performances.gain_avg).format('+0.00') + '%' : '-'
    let avg_recent = performances ? numeral(performances.gain_avg_3m).format('+0.00') + '%' : '-'

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => this.handleClose()}>
        <DialogTitle><Typography variant='h4'>Le statistiche del titolo</Typography></DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardHeader title={<Typography variant='overline'>Market - ISIN - Sector</Typography>} />
                <CardContent>
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '20%' }}><img style={{maxHeight: 80}} alt='Logo' src={instrumentLogo} /></Box>
                    <Box>
                      <Typography variant='h5'>{instrumentName}</Typography>
                      <Typography variant='subtitle1'>{instrumentTicker}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardHeader title={<Typography variant='overline'>Performance media</Typography>} action={<HelpOutlineOutlinedIcon />} />
                <CardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
                      <Box sx={{width: '50%'}}><Typography variant='h3'>{avg_overall}</Typography></Box>
                      <Box sx={{width: '50%'}}><Typography variant='h3'>{avg_recent}</Typography></Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
                      <Box sx={{width: '50%'}}>Pos. vs Neg.</Box>
                      <Box sx={{width: '50%'}}>
                        <LinearProgress sx={{backgroundColor: '#f00'}} color='success' variant='determinate' value={60} size={40} thickness={4}/>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
                      <Box sx={{width: '50%'}}>Migliore operazione</Box>
                      <Box sx={{width: '50%'}}>
                        <LinearProgress variant='determinate' value={38} size={40} thickness={4}/>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
                      <Box sx={{width: '50%'}}>Peggiore operazione</Box>
                      <Box sx={{width: '50%'}}>
                        <LinearProgress variant='determinate' value={25} size={40} thickness={4}/>
                      </Box>
                    </Box>
                  </Box>

                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardHeader title={<Typography variant='overline'>OPERAZIONI VINCENTI CONSECUTIVE</Typography>} action={<HelpOutlineOutlinedIcon />} />
                <CardContent>
                <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
                      <Box sx={{width: '50%'}}>
                        <Typography variant='h3'>{last_series_count}</Typography>
                        <Typography variant='subtitle1'>Ultima serie</Typography>
                        <Typography variant='subtitle2'>{last_dates}</Typography>
                      </Box>
                      <Box sx={{width: '50%'}}>
                      <Typography variant='h3'>{best_series_count}</Typography>
                        <Typography variant='subtitle1'>Serie migliore</Typography>
                        <Typography variant='subtitle2'>{best_dates}</Typography>
                      </Box>
                    </Box>

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

export default FullDialog;
