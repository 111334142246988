import { withTheme } from '@emotion/react';
import { ManageSearch, SearchOutlined } from '@mui/icons-material';
import { Box, Button, InputAdornment, Slide, Typography } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { t } from 'i18next';
import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../commons/Colors';
import { getFlag } from '../../commons/Utils';
import DialogHelp from '../../elements/DialogHelp';
import DialogTradeMob from '../../elements/DialogTradeMob';
import SkeletonListAvatar from '../../elements/SkeletonListAvatar';
import api from '../../services/api';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        py: 1,
      }}
    >
      <GridToolbarQuickFilter
        label={t('myst_trades_filterbyname')}
        placeholder={t('myst_trades_filterbyname')}
        variant='outlined'
        size='medium'
        sx={{ width: "100%" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ManageSearch />
            </InputAdornment>
          ),
        }}

        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MobMyStatsTrades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      idTrade: 0,

      columns: [],
      rows: [],

      posDialogOpen: false,
      posDialogTitle: "",
      posDialogHist: [],
      posDialogData: [],
      max_min_items: [],

      helpOpen: false,
      helpText: ""
    }
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    
    api.get('v2/myst/wa/trades/500/0/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        const trades = data.trades
        const columns = [
          {
            field: 'name',
            renderCell: (params) => {
              return (
                <Typography variant="body2" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}><b>{params.value}</b></Typography>
              );
            },
            headerName: t('myst_trades_name'), filterable: true, hideable: false, disableColumnMenu: true, flex: 2
          }, {
            field: 'perc_gain',
            renderCell: (params) => {
              let col = parseFloat(params.value) > 0 ? this.props.theme.palette.colorPositive : this.props.theme.palette.colorNegative
              return (
                <Typography variant='body2' sx={{ color: col }}>{numeral(params.value).format('+0.00') + '%'}</Typography>
              );
            },
            headerName: t('myst_trades_profit'), type: 'number', flex: 1, filterable: false, hideable: false, disableColumnMenu: true
          },
        ];

        let rows = [
        ];

        trades.forEach((row) => {
          rows.push({
            id: row.id_trade,
            country: { avatar: getFlag(row.country) },
            name: row.name,
            open_signal: row.open_signal,
            open_date: moment('20' + row.open_date).format('DD/MM/YYYY'),
            open_price: numeral(row.open_price).format('1,000.00'),
            exit_date: moment('20' + row.exit_date).format('DD/MM/YYYY'),
            exit_price: numeral(row.exit_price).format('1,000.00'),
            perc_gain: row.perc_gain
          })
        });

        this.setState({ isLoading: false, data, columns, rows });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  closePos() {
    this.setState({ posDialogOpen: false, posDialogTitle: "", posDialogHist: [], posDialogData: [] });
  }

  loadPosition = (idTrade) => {
    this.setState({ posDialogOpen: true, idTrade });
  }

  handleRowClick = (params) => {
    this.loadPosition(params.id)
  };

  render() {
    const { data, columns, rows, isLoading, posDialogOpen, posDialogTitle, posDialogHist, posDialogData, max_min_items, options, idTrade } = this.state;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsTradesTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              {this.constructor.name + "Help"}
            </Trans>
          }
          okButton="ok" />

        <DialogTradeMob
          open={posDialogOpen}
          closePos={() => this.closePos()}
          TransitionComponent={Transition}
          posDialogData={posDialogData}
          posDialogHist={posDialogHist}
          posDialogTitle={posDialogTitle}
          max_min_items={max_min_items}
          options={options}
          idTrade={idTrade}
          theme={this.props.theme}
        />

        <Typography gutterBottom variant="overline" component="h2"><b><Trans>{"MyStatsTradesTitle"}</Trans></b></Typography>
        <Box sx={{ width: '100%' }}>
          {
            isLoading ? <SkeletonListAvatar /> :
              rows && rows.length > 0 ?
                <DataGrid
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  sx={{
                    borderColor: this.props.theme.palette.grid
                  }}
                  density='standard'
                  onRowClick={this.handleRowClick} {...data}
                  onCellClick=""
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  autoHeight={true}
                  hideFooterSelectedRowCount={true}
                ></DataGrid>
                : <Empty img={this.constructor.name} />
          }
        </Box>
      </>
    );
  }
}

export default withTheme(MobMyStatsTrades);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyStatsTradesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans><b>{t('myst_trades_emptyTitle')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_trades_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 4 }}><Button variant='contained' disableElevation component={NavLink} to='/mybraino'><Trans>{t('myst_trades_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}