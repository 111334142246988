import React, { Component, createContext } from 'react';

export const GlobalContext = createContext(null)

export class GlobalProvider extends Component {
    setTermId = process_id => {
            this.setState('0');
    };
    state = {
        userId: 'pvd-userid',
        tId: 'pvd-tid'

    }
    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }
}