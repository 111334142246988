import React, { Component } from 'react';
import { Alert, Button, Slide, Snackbar } from "@mui/material";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

class BrainoSnackError extends Component {

  render() {
    return (
      <Snackbar
        open={this.props.open}
        autoHideDuration={4000}
        TransitionComponent={SlideTransition}
        onClose={this.props.onClose}
        message={this.props.message}
        action={<Button size="small" onClick={this.props.onClose}>ok</Button>}
      >
        <Alert sx={{width: "100%"}} severity='error'>{this.props.message}</Alert>
      </Snackbar>
    );
  }
}

export default BrainoSnackError;
