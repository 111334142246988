import React from 'react';
import { Box } from "@mui/material";
import { Skeleton } from '@mui/material';

function SkeletonScore() {
    return (
        <div>
            <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                <Box paddingRight="16px" sx={{flex:'40%'}}>
                    <Skeleton variant="circle" width={40} height={40} />
                </Box>
                <Box display="flex" sx={{flex:'60%'}} alignItems="start" flexDirection="column" width="100%">
                    <Skeleton variant="text" width="30%" />
                    <Skeleton variant="text" width="70%" />
                </Box>
            </Box>
        </div>
    );
}

export default SkeletonScore;