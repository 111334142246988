import { Card, CardContent, CardHeader, Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import PaypalCheckout from "./PaypalCheckout";

export default function Paypal() {
  return (
    <Container sx={{ p: 2 }}>
      <Toolbar />
      <Card>
        <CardHeader></CardHeader>
        <CardContent sx={{ p: 4 }}>
            <PaypalCheckout />
        </CardContent>
      </Card>
    </Container>
  );
}