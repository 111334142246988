import { CheckOutlined } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';


class ProfilingRisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0
    }
  }

  componentDidMount() {
  }

  handleChange(value) {
    this.setState({ selected: value })
    this.props.handleChange(this.props.q, [value])
    this.props.handleStatus(this.props.q, value > 0 ? true : false)
  }

  render() {
    const { selected } = this.state;
    return (
      <Box sx={{ py: 2 }}>
        <Typography gutterBottom variant='body2'><Trans>q_1</Trans></Typography>
        <Grid container spacing={1}>
          {[
            { 'l': t("a_risk_lo"), 'v': 1 },
            { 'l': t("a_risk_md"), 'v': 2 },
            { 'l': t("a_risk_hi"), 'v': 3 },
          ].map((item) => {
            const checked = selected === item.v;
            return (
              <Grid item xs={6} sm={4} md={4} lg={3}>
                <Chip
                  sx={{ m: 1, minWidth: "100%" }}
                  size='medium'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  icon={checked ? <CheckOutlined /> : ''}
                  onClick={() => {
                    this.handleChange(item.v);
                  }}
                >

                </Chip>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default withTranslation()(ProfilingRisk);