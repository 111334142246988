import { withTheme } from '@emotion/react';
import { Button, CardActions, CardMedia, Fade, Toolbar, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import { t } from 'i18next';
import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

class SignupResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            newsletter1: true,
            newsletter2: false,
            open: false,
            username: null,
            password: null,
            errorOpen: false,
            errorMsg: '',
            done: true,
        }
    }

    render() {
        return (
            <Fade in={true} timeout={450}>
                <div>
                    <Toolbar />
                    <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                        {this.props.result ?
                            <Card variant="outlined" sx={{ width: '100%' }}>
                                <CardMedia sx={{objectFit: "contain"}} component="img" height={400} image='/img/Plans.svg'></CardMedia>
                                <CardContent sx={{ p: 1 }}>
                                    {t("mail_validation_successTitle")}
                                </CardContent>
                                <CardActions>
                                    <Button component={NavLink} to="/" variant='contained' disableElevation>Login</Button>
                                </CardActions>
                            </Card>
                            :
                            <Card variant="outlined" sx={{ width: '100%' }}>
                                <CardMedia sx={{objectFit: "contain"}} component="img" height={400} image='/img/email/mailValidationFailed.png'></CardMedia>
                                <CardContent sx={{ p: 1 }}>
                                    <Typography variant='h6'>{t("mail_validation_failedTitle")}</Typography>
                                    {t("mail_validation_failed_subtitle")}
                                </CardContent>
                                <CardActions>
                                    <Button component={NavLink} to="/" variant='contained' disableElevation>Login</Button>
                                </CardActions>
                            </Card>

                        }
                    </Container>
                </div>
            </Fade>
        )
    }
}
export default withParams(withTheme(SignupResult));