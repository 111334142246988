export const COLORS = {
    black: '#565656',
    green: '#33cd99',
    red: '#ed5565',
    yellow: '#ff8e00',
    blue: '#8d21e9',
    grey: '#848484',
    lightgrey: '#a6a6a6',
    gridgrey: '#e6e6e6',
    white: '#fff',
}
