import { withTheme } from '@emotion/react';
import { AlarmOnOutlined, DirectionsRunOutlined, FlagOutlined, HealingOutlined, HelpOutline, Inventory2Outlined } from '@mui/icons-material';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, LinearProgress, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Slide, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { getFlag } from '../commons/Utils';
import DialogHelp from '../elements/DialogHelp';
import DialogTrade from '../elements/DialogTrade';
import SkeletonListAvatar from '../elements/SkeletonListAvatar';
import api from '../services/api';

const url = ''
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const numberFormatter = item => numeral(item).format("0,00,0.00");

class MyPositions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],

      helpOpen: false,
      posDialogOpen: false,
      posDialogTitle: "",
      posDialogHist: [],
      posDialogData: [],
      max_min_items: [],
      idTrade: 0,

      neededReload: this.props.neededReload,
      tradeStatuses: {
        "ts2": <Tooltip title='Closing today'><AlarmOnOutlinedIcon sx={{ fontSize: 16 }} /></Tooltip>,
        "ts1": <Tooltip title='Just added'><FlagOutlined color='primary' sx={{ fontSize: 16 }} /></Tooltip>,
        "ts-1": <Inventory2Outlined sx={{ fontSize: 16 }} />,
        "ts0": <Tooltip title='In progress'><DirectionsRunOutlined color='primary' sx={{ fontSize: 16 }} /></Tooltip>,
      },
      selected: 'op'
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  loadPositions(tp) {
    this.setState({ isLoading: true, data: [] });

    tp = tp ? tp : this.state.selected
    api.get('myposs/' + localStorage.getItem('ID') + '/theta2/' + tp)
      .then(response => {
        const data = response.data
        this.setState({ data, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
  }

  loadPosition = (idTrade) => {
    this.setState({ posDialogOpen: true, idTrade });
  }

  closePos() {
    this.setState({ posDialogOpen: false });
    this.setState({ posDialogTitle: "" });
    this.setState({ posDialogHist: [] });
    this.setState({ posDialogData: [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.neededReload !== this.props.neededReload && this.props.neededReload) {
      console.log(prevProps.neededReload, this.props.neededReload)
      this.loadPositions()
    }
  }

  async componentDidMount() {
    this.loadPositions()
  }

  changeType(tp) {
    this.setState({ selected: tp })
    this.loadPositions(tp)
  }

  render() {
    const { data, posDialogOpen, options, posDialogHist, posDialogTitle, posDialogData, isLoading, max_min_items, selected, idTrade } = this.state;
    const { classes } = this.props;
    let positions = data.list ? data.list.pos : []
    let maxPositions = data.list ? data.list.max : "-"
    return (
      <>

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyPositionsTitle")}
          helpDialogContent={
            <Trans
              components={{
                flag: <FlagOutlined color='primary' />,
                directions_run: <DirectionsRunOutlined color='primary' />,
                alarm_on: <AlarmOnOutlined color='error' />,
                inventory_2: <Inventory2Outlined />,
                em: <em />,
                healing: <HealingOutlined color='warning' />,
              }}
            >
              {"MyPositionsHelp"}
            </Trans>
          }
          okButton="ok" />

        <DialogTrade
          open={posDialogOpen}
          closePos={() => this.closePos()}
          TransitionComponent={Transition}
          posDialogData={posDialogData}
          posDialogHist={posDialogHist}
          posDialogTitle={posDialogTitle}
          max_min_items={max_min_items}
          options={options}
          idTrade={idTrade}
          theme={this.props.theme}
        />

        <Card variant="outlined">
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2">{t("MyPositionsTitle")}</Typography>}
            subheader={
              data.list && selected === "op" ? (
                <>
                  <Typography color={"textSecondary"} variant="caption" component="div">{t("MyPositions_count_op", { pos: positions.length, avail: maxPositions })}</Typography>
                  <LinearProgress sx={{ width: 128 }} variant='determinate' value={positions.length * 100 / maxPositions} />
                </>)
                : data.list && selected === "cling" ?
                  <Typography gutterBottom color={"textSecondary"} variant="caption" component="div">{t("MyPositions_count_cling", { pos: positions.length })}</Typography>
                  : data.list && selected === "cl" ?
                    <Typography gutterBottom color={"textSecondary"} variant="caption" component="div">{t("MyPositions_count_cl", { pos: positions.length })}</Typography>
                    : <Typography gutterBottom color={"textSecondary"} variant="caption" component="div">Loading...</Typography>

            }
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutline />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ height: 308, overflow: 'auto' }}>
            <List >
              {positions.length > 0 ? positions.map((obj) => (
                <Box key={obj.id_trade}>
                  <ListItemButton onClick={() => this.loadPosition(obj.id_trade)}>
                    <ListItemAvatar><Avatar sx={{ width: 30, height: 30 }} alt={obj.name} src={getFlag(obj.country)} /></ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle2">{obj.name}</Typography>} secondary={<Typography color='secondary' variant="caption">{obj.dir}</Typography>} />
                    <ListItemSecondaryAction sx={{ textAlign: 'right' }}>
                      {/* <Chip
                      variant='filled'
                      size='small'
                      color={obj.is_good === "1" ? "pos" : "neg"}
                      sx={{color: "#FFF"}}
                      label={obj.perc_gain}
                      /> */}
                      <Typography variant="subtitle2" color={obj.is_good === "1" ? this.props.theme.palette.pos.main : this.props.theme.palette.neg.main}>{obj.perc_gain}</Typography>
                      <span>{this.state.tradeStatuses['ts' + obj.trade_status]}</span>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                  <Divider></Divider>
                </Box>
              )) :
                isLoading ? <SkeletonListAvatar />
                  : <Empty title={'titolo'} comment={'testo1'} type={selected} />
              }
            </List>
          </CardContent>
          <CardActions>
            {[
              { 'l': t("my_mypos_chip1"), 'v': 'op' },
              { 'l': t("my_mypos_chip2"), 'v': 'cling' },
              { 'l': t("my_mypos_chip3"), 'v': 'cl' },
            ].map((item) => {
              const checked = selected === item.v;
              return (
                <Chip
                  sx={{ width: "100%", maxWidth: 92 }}
                  size='small'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  onClick={() => {
                    this.changeType(item.v);
                  }}
                >

                </Chip>
              );
            })}
          </CardActions>
        </Card>
      </>
    );
  }
}

//export default MyAdvices;
export default withTheme(MyPositions);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ p: 2, flex: '40%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyPositionsEmpty.svg'} alt={this.props.img} style={{ maxWidth: "100%" }} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='body2'><Trans>{t('my_mypos_' + this.props.type + '_empty_text')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}