import React, { Component } from "react";
import { Dialog, DialogActions, Button, Typography, DialogContent, DialogTitle, Box, Slide, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Tooltip } from '@mui/material';
import { BookmarkAddOutlined, BookmarkRemove } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import SkeletonListAvatar from "./SkeletonListAvatar";
import { t } from "i18next";
import api from "../services/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogSearchMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: false,
      size: 20,
      block: 20,
      allowed: true,
    }
  }

  componentDidMount() {

  }

  search(value) {
    if (value)
      this.setState({ value });
    else
      value = this.state.value

    if (value.length > 2) {

      this.setState({ isLoading: true, size: 20 });
      let config = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
        }
      }
      api.get('names/' + value + '/instruments')
        .then(response => {
          const data = response.data
          this.setState({ data: data, isLoading: false });
        }).catch(error => {
          this.setState({ isLoading: false });
          if (401 === error.response.status) {
            this.setState({ allowed: false })
          } else {
            console.log(error);
          }
        })
    }
  }

  loadMore() {
    let size = this.state.size + this.state.block;
    this.setState({ size })
  }

  reset() {
    this.setState({ size: 20, data: null })
    this.props.closeSearch()
  }

  toggleFav(id, isFav) {
    
    if (isFav) {
      api.delete('favorite/' + localStorage.getItem('ID') + '/' + id)
        .then(response => {
          this.search()
          this.props.reloadFavorites()
        }).catch(error => {
          console.log(error);
        })
    } else {
      api.post('favorite/' + localStorage.getItem('ID') + '/' + id, '')
        .then(response => {
          this.search()
          this.props.reloadFavorites()
        }).catch(error => {
          console.log(error);
        })
    }
  }

  render() {
    const { data, size, isLoading, allowed } = this.state;
    return (
      <div>
        <Dialog fullScreen open={this.props.searchOpen} onEscapeKeyDown={() => this.reset()} onBackdropClick={() => this.reset()} TransitionComponent={Transition}>
          <DialogTitle>{this.props.searchOpen}
            <TextField label={t('MyFavorites_filterbyname')} autoFocus={true} size="medium" sx={{ width: '100%' }} onKeyUp={e => this.search(e.target.value)}></TextField>
          </DialogTitle>
          <DialogContent>
            {
              // >>> LOADING STATE
              isLoading ? <SkeletonListAvatar /> :
                // >>> DATA STATE
                data && data.length > 0 ?
                  <List>
                    {
                      data.filter((obj, idx) => idx < size).map((obj) => (
                        <div>
                          <ListItem>
                            <ListItemText primary={<Typography variant="subtitle2">{obj.name}</Typography>} secondary={obj.ticker} />
                            <ListItemSecondaryAction>
                              <IconButton aria-label="settings" onClick={() => this.toggleFav(obj.id, obj.isfav)}>
                                {obj.isfav ?
                                  <Tooltip title='remove from favorites'><BookmarkRemove color='primary' /></Tooltip> :
                                  <Tooltip title='add to favorites'><BookmarkAddOutlined /></Tooltip>}
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </div>
                      ))
                    }
                    {size < data.length ?
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button sx={{ mt: 2 }} onClick={() => this.loadMore()}>Load more... ({size}/{data.length})</Button>
                      </Box>
                      : ''}
                  </List>
                  :
                  // >>> NO DATA STATE
                  allowed ? <img alt="empty" src="/img/empty/SearchEmpty.svg" />
                    :
                    // >>> NOT ALLOWED STATE
                    <Unauthorized/>
            }
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={() => this.reset()} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default DialogSearchMob;


// ---------------------
// UNAUTHORIZED CONTENT
// ---------------------
class Unauthorized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img alt="Upgrade" src={'/img/401/Upgrade401.svg'} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box><Typography variant='subtitle2'>{t('Search401Title')}</Typography></Box>
            <Box sx={{pb:2}}><Typography variant='body2'>{t('Search401Text')}</Typography></Box>
            <Button disableElevation variant="contained" component={NavLink} to={'/plans'}>{t('Search401Button')}</Button>
          </Box>
        </Box>
      </>
    )
  }
}