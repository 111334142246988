import { createTheme } from "@mui/material";


const apptheme = createTheme({
  button: { textTransform: 'capitalize' },
  palette: {
    chartPrimary: '#c289f3',
    chartSecondary: '#ffcf44',
    colorRed: '#F00',
    grid: '#484848',
    sell: {
      light: '#A00',
      main: '#ff8e00',
      dark: 'F00',
      contrastText: '#fefefe',
    },
    buy: {
      light: '#33cd99!important',
      main: '#33cd99!important',
      dark: 'F00!important',
      contrastText: '#fefefe!important',
    },
    neg: {
      light: '#FF1744!important',
      main: '#FF1744!important',
      dark: '#FF1744!important',
      contrastText: '#fefefe!important',
    },
    pos: {
      light: '#0f0!important',
      main: '#33cd99!important',
      dark: 'F00!important',
      contrastText: '#fefefe!important',
    },
    background: {
      primary: '#33333D',
      default: '#33333D',
      paper: '#373741',
    },
    text: {
      primary: '#f0f0f0',
      secondary: '#bdbdbd',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      main: '#c289f3',
      light: 'purple',
      dark: 'violet',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#848484',
      dark: '#045d56',
      main: '#aaa',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    golden: {
      main: '#ffd700',
      light: '#ffd700',
      dark: '#ffd700',
    },
    silver: {
      main: '#c0c0c0',
      light: '#c0c0c0',
      dark: '#c0c0c0',
    },
    bronze: {
      main: '#cd7f32',
      light: '#cd7f32',
      dark: '#cd7f32',
    },
    black: {
      main: '#848484',
      light: '#848484',
      dark: '#848484',
      contrastText: '#fff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    common: {
      black: '#848484'
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px!important',
          borderColor: '#f0f0f0'
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: '0!important',
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: '0!important',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px!important',
          borderColor: '#333!important',
          borderWidth: '1px!important',
          borderStyle: 'solid',
          boxShadow: 'none!important',
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#484848!important',
          borderColor: '#484848!important'
        },
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '30px!important',
          height: '30px!important',
        },
      }
    },
    MuiContainer: {
      root: {
        padding: '0!important',
        margin: '0'
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: '1px',
          minHeight: '20px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#c289f3',
          color: '#fff',
          border: 0,
          fontFamily: 'Noto Sans'
        },
        arrow: {
          //backgroundColor: '#c289f3',
          color: '#c289f3',
        }
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#f0f0f0'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#faaf00!important'
        },
        iconEmpty: {
          color: '#848484!important'
        },
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#f0f0f0",
          "&.Mui-selected": {
            "color": "#c289f3!important"
          }
        },
      }
    },
  },
});

export default apptheme;