import eventBus from './commons/eventBus';

export default function registerServiceWorker() {
  if ('production' !== process.env.NODE_ENV) {
    return;
  }
  console.log(">>> STARTING...")
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      console.log(">>> LOADING...")
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        console.log(">>> REGISTERED...")
        registration.addEventListener('updatefound', () => {
          console.log(">>> updatefound...")
          const installingWorker = registration.installing;
          installingWorker.addEventListener('statechange', () => {
            console.log(">>> statechange...")
            if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
              console.log(">>> installed...")
              // New content is available; show a refresh button in your toolbar
              eventBus.dispatch("NEWVERSION", { message: "new version detected" })
            }
          });
        });
      });
    });

    navigator.serviceWorker.addEventListener("controllerchange", event => {
      console.log("controllerchange");
    });

    navigator.serviceWorker.ready.then(registration => {
      // This code will be executed once a new Service Worker is ready.
      console.log('New Service Worker detected silently');
    });
    // const wb = new Workbox('service-worker.js');
    // wb.addEventListener('installed', event => {
    //   console.log(">>> INSTALLED")
    //   /**
    //    * We have the condition - event.isUpdate because we don't want to show
    //    * this message on the very first service worker installation,
    //    * only on the updated
    //    */
    //   if (event.isUpdate) {
    //     eventBus.dispatch("NEWVERSION", {})

    //   }
    // });

    // wb.register();
  }
}