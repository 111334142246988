import { Toolbar } from "@mui/material";
import { Document, Page, PDFDownloadLink, Text, View } from "@react-pdf/renderer";
import React, { Component } from 'react';
import { hydrate } from 'react-dom';
import MyAdvices from "./MyAdvices";

const ModelCertificate = props => {
  return (
    <Document>
      <Page>

        <MyAdvices />

      </Page>
    </Document>
  );
};

export default class PDFLink extends Component {
  state = {
    loading: false,
  };

  download = (filename, url) => {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
    setTimeout(() => {
      const link = (
        <div id={divId}>
          {/* <PDFDownloadLink document={<ModelCertificate />} fileName="report.pdf">
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
      }
          </PDFDownloadLink> */}
          <PDFDownloadLink document={pdfContent} fileName={filename}>
            {({ blob, url, loading, error }) => {
              if (!loading && blob) {
                console.log("passo da qui 1 ")
                this.download(filename, window.URL.createObjectURL(blob));
                callback();
              }
            }}
          </PDFDownloadLink>
        </div>
      );
      console.log("passo da qui 2")

      const elem = document.createElement('div');
      document.getElementById('pdfButton').appendChild(elem);
      hydrate(link, elem);
    }, 500);
  };

  buildPDF = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true }, () => {
        this.createAndDownloadPDF(this.generatePDF(), `file.pdf`, `file`, () =>
          this.setState({ loading: false })
        );
      });
    }
  };

  generatePDF = () => {
    // CertificatePDF is a component that returns a PDF <Document />
    return <ModelCertificate {...this.props} />;
  };

  render() {
    const { loading } = this.state;
    return loading ? (
      <div id="pdfButton">Loading...</div>
    ) : (
      <>
        <Toolbar />
        <button onClick={this.buildPDF}>
          Click here to download a certificate
        </button>
      </>
    );
  }
}