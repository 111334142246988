import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React, { Component } from 'react';
import SkeletonChart from '../../elements/SkeletonChart';
import SkeletonGauge from '../../elements/SkeletonGauge';
import SkeletonList2 from '../../elements/SkeletonList2';
import BrainoSnackbar from '../../commons/BrainoSnackbar';
import api from '../../services/api';

class MobDetailsOverviewLight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      descriptionOpen: false,
      bookmarked: false,
      snackOpen: false,
      snackMessage: '',
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    
    api.get('snapshot/' + localStorage.getItem('ID') + '/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data, bookmarked: data.isFav });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  toggleFav() {
    

    if (this.state.bookmarked) {
      api.delete('favorite/' + localStorage.getItem('ID') + '/' + this.props.id)
        .then(response => {
          this.setState({ bookmarked: !this.state.bookmarked, snackOpen: true, snackMessage: !this.state.bookmarked === true ? t('MyFavorites_added_text') : t('MyFavorites_removed_text') });
        }).catch(error => {
          console.log(error);
        })
    } else {
      api.post('favorite/' + localStorage.getItem('ID') + '/' + this.props.id, '')
        .then(response => {
          this.setState({ bookmarked: !this.state.bookmarked, snackOpen: true, snackMessage: !this.state.bookmarked === true ? t('MyFavorites_added_text') : t('MyFavorites_removed_text') });
        }).catch(error => {
          console.log(error);
        })
    }
  }

  render() {
    const { data, bookmarked, isLoading, snackMessage, snackOpen } = this.state;
    return (
      <>
        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        {data ? (
          <Box sx={{ display: 'flex', alignItems: 'start', py: 2 }}>
            <Box sx={{ flex: '70%', pr: 2, display: 'flex', flexDirection: "column" }}>
              <Typography className='big' variant="h4">{data.n}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1 }} className='big' variant="body1" color={''}>{data.t + '.' + data.mc}</Typography>
                <IconButton
                  onClick={() => {
                    this.toggleFav();
                  }}
                >
                  <Tooltip title={bookmarked ? 'Remove from favorites' : 'Add to favorites'} arrow>
                    {bookmarked ? <Bookmark sx={{ fontSize: 20 }} color='primary' /> : <BookmarkBorder sx={{ fontSize: 20 }} color='primary' />}
                  </Tooltip>
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ flex: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2 }}>
              {
                isLoading ? <SkeletonChart /> :
                  data.logo ?
                    <img src={"https://eodhistoricaldata.com" + data.logo} style={{ maxWidth: "100%", maxHeight: 80 }} />
                    : "-"
              }
            </Box>
          </Box>
        )
          : isLoading ?
            <Box sx={{ display: 'flex', alignItems: 'start', py: 2 }}>
              <Box sx={{ flex: '70%', display: 'flex', flexDirection: "column" }}><SkeletonList2 rows={1} /></Box>
              <Box sx={{ flex: '30%', display: 'flex', justifyContent: "center" }}><SkeletonGauge width={60} height={60} /></Box>
            </Box>
            : 'no data'
        }
      </>
    );
  }
}

export default MobDetailsOverviewLight;
