import React, { Component } from 'react';
import { Button, Slide, Snackbar } from "@mui/material";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

class BrainoSnackbar extends Component {

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.props.open}
        autoHideDuration={3000}
        TransitionComponent={SlideTransition}
        onClose={this.props.onClose}
        message={this.props.message}
        action={<Button size="small" onClick={this.props.onClose} color='inherit'>ok</Button>}
      />
    );
  }
}

export default BrainoSnackbar;
