import { Box, Button, Typography } from '@mui/material';
import api from '../../services/api';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import DialogHelp from '../../elements/DialogHelp';
import SkeletonChart from '../../elements/SkeletonChart';

class MobMyStatsSynthesis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      my_avg_perf: 0,
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      chart_options: 0,
      chart_type: "",
      chart_height: "",
      chart_series: 0
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    
    api.get('v2/myst/wa/synthesis/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data

        var options = {
          series: [{
            name: t('myst_synthesis_chartTitle'),
            data: [
              { 'y': data.averages.my_avg, 'x': t('myst_synthesis_chart_my') },
              { 'y': data.averages.all_avg, 'x': t('myst_synthesis_chart_community') }]
          }],
          chart: {
            type: 'bar',
            height: 125,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: ['#33cd99', '#8d21e9'],
          grid: {
            show: false
          },
          legend: {
            show: false,
          },
          yaxis: {
            categories: [t('myst_synthesis_chart_my'), t('myst_synthesis_chart_community')],
            labels: {
              style: {
                colors: ['#484848'],
                fontSize: '12px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
              show: true
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              barHeight: '80%',
              borderRadius: 4,
              dataLabels: {
                position: 'top',
                maxItems: 100,
                hideOverflowingLabels: true,
                orientation: 'horizontal'
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: 8,
            style: {
              fontSize: '14px',
              colors: ['#fafafa'],
              fontFamily: 'Roboto',
              fontWeight: 500,
            },
            background: {
              enabled: false,
              foreColor: '#848484',
              opacity: 0.7,
              padding: 4,
              borderWidth: 0,
            },
            formatter: function (val, opts) {
              return numeral(val).format('+0.0') + '%'
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            enabled: true,
            x: {
              formatter: function (val, opts) {
                return val
              },
            },
            y: {
              formatter: function (val, opts) {
                return numeral(val).format('+0.0') + '%'
              },
            },
            marker: {
              show: false,
            },
          },
        };
        this.setState({
          chart_type: 'bar', chart_height: '125', chart_options: options, data, my_avg_perf: data.averages.my_avg,
          chart_series: [{
            name: t('myst_synthesis_chartTitle'),
            data: [
              { 'y': data.averages.my_avg, 'x': t('myst_synthesis_chart_my') },
              { 'y': data.averages.all_avg, 'x': t('myst_synthesis_chart_community') }]
          }]
        });
        this.setState({ data, isLoading: false })
      }).catch(error => {
        this.setState({ isLoading: false })
        console.log(error);
      }).finally(

    )
  }

  render() {
    const { data, my_avg_perf, isLoading } = this.state;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsSynthesisTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsSynthesisHelp
            </Trans>
          }
        />

        <Box sx={{ mb: 3 }}>
          <Typography gutterBottom variant="overline" component="h2"><b><Trans>{"MyStatsSynthesisTitle"}</Trans></b></Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: '50%' }}>
              {
                isLoading ? <Box sx={{p:1}}><SkeletonChart height={66} /></Box> :
                  <>
                    <Typography className='big' variant="h4" component="h2" >
                      {my_avg_perf ? numeral(my_avg_perf).format('+0.0') + '%' : '-'}
                    </Typography>
                    <Typography gutterBottom variant="caption" color="secondary" >
                      <Trans>myst_synthesis_profit_label</Trans>
                    </Typography>
                  </>
              }
            </Box>
            <Box sx={{ flex: '50%' }}>
              {
                isLoading ? <Box sx={{p:1}}><SkeletonChart height={66} /></Box> :
                  <>
                    <Typography className='big' variant="h4" component="h2" >
                      {my_avg_perf ? numeral(data.winrate).format('+0.0') + '%' : '-'}
                    </Typography>
                    <Typography gutterBottom variant="caption" color="secondary" >
                      <Trans>myst_synthesis_winrate_label</Trans>
                    </Typography>
                  </>
              }
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default MobMyStatsSynthesis;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img src={'/img/empty/' + this.props.img + 'Empty.svg'} alt={this.props.img} style={{maxWidth: 150}} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans>{t('myst_synthesis_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_synthesis_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 2 }}><Button component={NavLink} to='/mybraino' variant='outlined'><Trans>{t('myst_synthesis_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}