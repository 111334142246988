import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { Close, EmailOutlined, LockOutlined } from '@mui/icons-material';
import { IconButton, Alert, AlertTitle, Box, Checkbox, Collapse, Fade, FormControlLabel, Typography, InputAdornment } from '@mui/material';
import { withTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { LoadingButton } from '@mui/lab';
import api from '../services/api';

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            newsletter1: true,
            newsletter2: false,
            open: false,
            username: null,
            password: null,
            errorOpen: false,
            errorMsg: '',
            done: false,
        }
    }

    signup() {
        this.setState({ isLoading: true, errorOpen: false });
        let payload = { email: this.state.username, password: this.state.password, newsletter: this.state.newsletter1, newsletter2: this.state.newsletter2 }
        api.post('register', payload)
            .then(response => {
                const data = response.data
                this.setState({ data });

                if (data.result === 'OK') {
                    this.setState({ done: true })
                } else {
                    this.setState({ errorOpen: true, errorMsg: data.msg })
                }
            }).catch(error => {
                console.log(error);
                this.setState({ isLoading: false });
            }).finally(() => {
                this.setState({ isLoading: false });
            })
    }

    handleChange(type, value) {
        this.setState({ [type]: value })
        if (type === 'newsletter1')
            this.setState({ open: !value })
        console.log(this.state)
    }


    render() {
        const { isLoading, newsletter1, newsletter2, open, errorOpen, errorMsg, done, username } = this.state;
        return (
            <Fade in={true} timeout={450}>
                <div>
                    {isBrowser ?
                        <BrowserView>
                            <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                                <Card variant="outlined" sx={{ width: '100%' }}>
                                    <CardContent sx={{ padding: '32px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                                                <img style={{ margin: '16px' }} height={80} alt="Logo" src="/LogoColVert.svg" />
                                            </Box>
                                            <Box sx={{ display: 'flex', pb: 4, width: '100%', alignItems: 'end' }}>
                                                <Box sx={{ flex: '100%' }}>
                                                    <Typography variant='h4' className='big'><Trans>signup_button</Trans></Typography>
                                                    <Typography variant='caption' color={'secondary'}><Trans>signupTitle</Trans></Typography>
                                                </Box>
                                            </Box>
                                            {!done ?
                                                <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, width: '100%' }}>
                                                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                        <TextField
                                                            label="Email"
                                                            variant="outlined"
                                                            onChange={e => this.handleChange('username', e.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailOutlined />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                        <TextField
                                                            type="password"
                                                            label="Password"
                                                            variant="outlined"
                                                            onChange={e => this.handleChange('password', e.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <LockOutlined />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <Collapse timeout={150} in={errorOpen}>
                                                        <Alert
                                                            severity="warning"
                                                            color='error'
                                                            action={
                                                                <IconButton
                                                                    aria-label="close"
                                                                    color="inherit"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        this.setState({ errorOpen: false })
                                                                    }}><Close fontSize="inherit" /></IconButton>
                                                            }
                                                            sx={{ mb: 2 }}>

                                                            <Trans>{errorMsg}</Trans>
                                                        </Alert>
                                                    </Collapse>
                                                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                        <Typography variant='caption'>
                                                            <Trans components={{
                                                                linkTo_terms: <a href="https://braino.ai/terms.html" rel="noreferrer" target="_blank"></a>,
                                                                linkTo_privacy: <a href="https://braino.ai/privacy.html" rel="noreferrer" target="_blank"></a>
                                                            }}>signup_terms</Trans>
                                                        </Typography>
                                                    </FormControl>
                                                    <FormControlLabel sx={{ pb: 1 }}
                                                        control={
                                                            <Checkbox defaultChecked={newsletter1} onChange={e => this.handleChange('newsletter1', e.target.checked)} />
                                                        }
                                                        label={<Typography variant='body2'><Trans>signup_newsletter</Trans></Typography>}
                                                    />
                                                    <Collapse timeout={150} in={open}>
                                                        <Alert severity="warning" color='error' sx={{ pb: 2 }}>
                                                            <Trans>reg_alert_msg</Trans>
                                                        </Alert>
                                                    </Collapse>
                                                    <FormControlLabel sx={{ py: 2 }}
                                                        control={
                                                            <Checkbox defaultChecked={newsletter2} onChange={e => this.handleChange('newsletter2', e.target.checked)} />
                                                        }
                                                        label={<Typography variant='caption'><Trans>signup_newsletter2</Trans></Typography>}
                                                    />
                                                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                        <LoadingButton loading={isLoading} loadingPosition="center" startIcon={""} size="large" onClick={() => this.signup()} variant="contained" color="primary"><Trans>signup_button</Trans></LoadingButton>
                                                    </FormControl>
                                                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                        <Typography variant='body2'><Trans>signup_back_login_1</Trans> <NavLink to='/'><Trans>signup_back_login_2</Trans></NavLink></Typography>
                                                    </FormControl>
                                                </Box>
                                                :
                                                <Box>
                                                    <Alert variant='standard' color='success'>
                                                        <Typography variant="subtitle1">
                                                            <Trans
                                                                i18nKey='signup_completed'
                                                                values={{ username }}
                                                                components={{
                                                                    b: <b></b>,
                                                                    div: <div></div>,
                                                                    br: <br />,
                                                                    linkTo_support: <a target="_blank" rel="noopener noreferrer" href="mailto:support@wavenure.com"></a>
                                                                }}></Trans>
                                                        </Typography>
                                                    </Alert>
                                                </Box>
                                            }
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Container>
                        </BrowserView>
                        :
                        <MobileView>
                            <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                                        <img height={80} alt="Logo" src="/LogoColVert.svg" />
                                    </Box>
                                    <Box sx={{ display: 'flex', pb: 4, width: '100%', alignItems: 'end' }}>
                                        <Box sx={{ flex: '100%' }}>
                                            <Typography variant='h4' className='big'><Trans>signup_button</Trans></Typography>
                                            <Typography variant='caption' color={'secondary'}><Trans>signupTitle</Trans></Typography>
                                        </Box>
                                    </Box>
                                    {!done ?
                                        <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, width: '100%' }}>
                                            <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                <TextField
                                                    label="Email"
                                                    variant="outlined"
                                                    onChange={e => this.handleChange('username', e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailOutlined />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                <TextField
                                                    type="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    onChange={e => this.handleChange('password', e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockOutlined />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                            <Collapse timeout={150} in={errorOpen}>
                                                <Alert
                                                    severity="warning"
                                                    color='error'
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                this.setState({ errorOpen: false })
                                                            }}><Close fontSize="inherit" /></IconButton>
                                                    }
                                                    sx={{ mb: 2 }}>

                                                    <Trans>{errorMsg}</Trans>
                                                </Alert>
                                            </Collapse>
                                            <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                <Typography variant='caption'>
                                                    <Trans components={{
                                                        linkTo_terms: <a href="https://braino.ai/terms.html" rel="noreferrer" target="_blank"></a>,
                                                        linkTo_privacy: <a href="https://braino.ai/privacy.html" rel="noreferrer" target="_blank"></a>
                                                    }}>signup_terms</Trans>
                                                </Typography>
                                            </FormControl>
                                            <FormControlLabel sx={{ pb: 1 }}
                                                control={
                                                    <Checkbox defaultChecked={newsletter1} onChange={e => this.handleChange('newsletter1', e.target.checked)} />
                                                }
                                                label={<Typography variant='body2'><Trans>signup_newsletter</Trans></Typography>}
                                            />
                                            <Collapse timeout={150} in={open}>
                                                <Alert severity="warning" color='error' sx={{ pb: 2 }}>
                                                    <Trans>reg_alert_msg</Trans>
                                                </Alert>
                                            </Collapse>
                                            <FormControlLabel sx={{ py: 2 }}
                                                control={
                                                    <Checkbox defaultChecked={newsletter2} onChange={e => this.handleChange('newsletter2', e.target.checked)} />
                                                }
                                                label={<Typography variant='caption'><Trans>signup_newsletter2</Trans></Typography>}
                                            />
                                            <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                <LoadingButton loading={isLoading} loadingPosition="center" startIcon={""} size="large" onClick={() => this.signup()} variant="contained" color="primary"><Trans>signup_button</Trans></LoadingButton>
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                <Typography variant='body2'><Trans>signup_back_login_1</Trans> <NavLink to='/'><Trans>signup_back_login_2</Trans></NavLink></Typography>
                                            </FormControl>
                                        </Box>
                                        :
                                        <Box>
                                            <Alert variant='standard' color='success'>
                                                <Typography variant="subtitle1">
                                                    <Trans
                                                        i18nKey='signup_completed'
                                                        values={{ username }}
                                                        components={{
                                                            b: <b></b>,
                                                            div: <div></div>,
                                                            br: <br />,
                                                            linkTo_support: <a target="_blank" rel="noopener noreferrer" href="mailto:support@wavenure.com"></a>
                                                        }}></Trans>
                                                </Typography>
                                            </Alert>
                                        </Box>
                                    }
                                </Box>
                            </Container>
                        </MobileView>
                    }
                </div>
            </Fade>
        )
    }
}
export default withTheme(Signup);