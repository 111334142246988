import { withTheme } from '@emotion/react';
import { BookmarkAdd, BookmarkBorderOutlined, DeleteOutline, DragIndicator, HelpOutline, HelpOutlineOutlined, Leaderboard, NotificationsActiveOutlined, NotificationsActive as NotificationsActiveOutlinedIcon, NotificationsOffOutlined } from '@mui/icons-material';
import { Avatar, Box, Fade, IconButton, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Component } from 'react';
import { DraggableCore } from 'react-draggable';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getFlag } from '../../commons/Utils';
import DialogHelp from '../../elements/DialogHelp';
import DialogSearchMob from '../../elements/DialogSearchMob';
import SkeletonListAvatar from '../../elements/SkeletonListAvatar';
import { DragElement } from '../../commons/DragElement';
import api from '../../services/api';

class MobMyFavorites extends Component {
  constructor(props) {
    super(props);
    this.draggableRef = React.createRef();
    this.parentRef = React.createRef();
    this.handleClose = this.handleStop.bind(this);
    this.handleDrag = this.handleDrag.bind(this);

    this.state = {
      data: [],
      collapsed: true,
      helpOpen: false,
      isDialogOpen: false,
      neededReload: this.props.neededReload,
      yStart: (window.innerHeight - 56 - 56) / 2, // initial position of draggable component
      yPos: (window.innerHeight - 56 - 56) / 2,
      yMax: 0,
      yDir: 0,
    }
  }

  loadFavorites() {
    this.setState({ isLoading: true })
    
    api.get('trading/favorites/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ data: data });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  deleteFav(id) {
    
    api.delete('favorite/' + localStorage.getItem('ID') + '/' + id)
      .then(response => {
        this.loadFavorites()
        this.props.notifyAdvicesChange()
      }).catch(error => {
        console.log(error);
      })
  }

  handleOpen() {
    this.setState({
      isDialogOpen: true
    });
  }
  handleClose() {
    this.setState({
      isDialogOpen: false
    });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  closeSearchDialog() {
    this.setState({ searchOpen: false })
  }

  openSearchDialog() {
    this.setState({ searchOpen: true })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.neededReload) {
      console.log(prevProps.neededReload)
      this.loadFavorites()
    }
  }

  async componentDidMount() {
    this.loadFavorites()
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  handleStart = (event, ui) => {
    this.setState({
      collapsed: true,
    })
  }

  handleDrag = (event, ui) => {
    const { yStart } = this.state;
    const newY = ui.y;
    const parentTop = this.parentRef.current.getBoundingClientRect().top - 56
    if (newY <= parentTop) {
      this.setState({
        yPos: parentTop,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: false,
      });
    } else if (newY < yStart) {
      this.setState({
        yPos: newY,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    } else {
      this.setState({
        yPos: yStart,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    }
  }

  handleStop = (event, ui) => {
    const { yDir, yStart, yMax } = this.state
    if (yDir > 5) {
      this.setState({
        yPos: yStart,
        collapsed: true,
      })
    } else if (yDir < 5) {
      this.setState({
        yPos: yMax,
        collapsed: false,
      })
    }
  }

  render() {
    const { data, collapsed, searchOpen, isLoading, yPos } = this.state;
    const bg = "white"
    const draggableStyle = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      cursor: 'move',
      border: '1px solid #e6e6e6',
      borderBottom: 0,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,

    };
    return (
      <div>
        <DialogSearchMob
          searchOpen={searchOpen}
          closeSearch={() => this.closeSearchDialog()}
          reloadFavorites={() => this.loadFavorites()}
        />

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyFavoritesTitle")}
          helpDialogContent={<Trans
            components={{
              em: <em />, i: <i />,
              bookmark_border: <BookmarkBorderOutlined sx={{ verticalAlign: 'middle' }} />,
              notifications_active: <NotificationsActiveOutlined sx={{ verticalAlign: 'middle' }} />,
              delete: <DeleteOutline sx={{ verticalAlign: 'middle' }} />,
            }}>{"MyFavoritesHelp"}</Trans>
          }
          okButton="ok" />

        <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", overflow: 'hidden', position: 'relative' }}>
          <Box sx={{ p: 2, display: 'flex', flex: '50%', position: 'relative', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center' }}><Typography variant="h4" className='big'>Hello</Typography></Box>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <IconButton data-testid="addToFavorites" disabled={isLoading ? true : false} color={'primary'} onClick={() => this.openSearchDialog()}><BookmarkAdd /></IconButton>
                <IconButton component={NavLink} to={'/mystats'} color='primary'><Leaderboard /></IconButton>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ maxHeight: '240px' }} src="img/MobMyFavorites.svg" />
              </Box>
              <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='caption' color="textSecondary">You have</Typography>
                <Typography variant='h2' className='big'>
                  {isLoading ? <Skeleton width={60} /> : data.filter(item => item.today_alert === '1').length}
                </Typography>
                <Typography variant='caption' color="textSecondary">alerts on your favs</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flex: '50%', position: 'relative' }}></Box>

          <DraggableCore
            axis="y" handle=".handle" onDrag={this.handleDrag} onStop={this.handleStop}
          >
            <Box ref={this.draggableRef} style={{ ...draggableStyle, background: bg, maxHeight: '100%', top: yPos, transition: "all 300ms cubic-bezier(.22,.68,0,1.4)" }}>
              <Box className='handle' sx={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                background: bg,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 2,
              }}>
                <DragElement />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: '50%' }}>
                  <Typography sx={{ px: 2, }} gutterBottom variant="overline">{t("MyFavoritesTitle")}</Typography>
                </Box>
                <Box sx={{ flex: '50%', textAlign: 'right', mr: 1 }}>
                  <Fade in={!collapsed} timeout={{ enter: 800, exit: 250 }}>
                    <IconButton sx={{ transition: 'All 2.5s linear' }} color={'primary'} onClick={() => this.openSearchDialog()}><BookmarkAdd /></IconButton>
                  </Fade>
                  <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                    <HelpOutlineOutlined />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ background: bg, maxHeight: "calc(100% - 56px - 40px)", overflow: collapsed ? "hidden" : "auto", }}>
                <List sx={{ background: bg }}>
                  {data.length > 0 ? data.map((obj) => (
                    <ListItemButton gutterBottom key={"favs_" + obj.id_instrument} component={NavLink} to={"/detail/" + obj.id_instrument}>
                      <ListItemAvatar>
                        <Avatar alt={obj.country_name} src={getFlag(obj.country_name)} />
                      </ListItemAvatar>
                      <ListItemText primary={<Typography style={{ whiteSpace: 'nowrap', maxWidth: '70%', overflow: "hidden", textOverflow: "ellipsis" }}><b>{obj.inst_name}</b></Typography>}
                        secondary={obj.today_alert == 1 ?
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <NotificationsActiveOutlinedIcon sx={{ mr: 1, fontSize: 20 }} color='primary' />
                            <Typography variant='caption'>{t("MyFavorites_signal_text")}</Typography>
                          </Box> :
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <NotificationsOffOutlined sx={{ mr: 1, fontSize: 20 }} color='secondary' />
                            <Typography variant='caption'>{t("MyFavorites_nosignal_text")}</Typography>
                          </Box>
                        } />
                      <ListItemSecondaryAction >
                        <IconButton color="secondary" aria-label="settings"
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation(); // stop click event from bubbling up to ListItemButton
                            this.deleteFav(obj.id_instrument);
                          }}>
                          <DeleteOutline />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  )) :
                    isLoading ? <Box sx={{ p: 2 }}><SkeletonListAvatar /></Box>
                      : <Empty />
                  }
                </List>
              </Box>
            </Box>
          </DraggableCore>
        </Box >
      </div >
    );
  }
}

//export default MyAdvices;
export default withTheme(MobMyFavorites);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', m: 1, py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%' }}><img src={'/img/empty/MyFavoritesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='subtitle2'><Trans><b>{t('MyFavorites_emptyTitle')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('MyFavorites_empty')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}