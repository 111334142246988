import React, { Component } from 'react';
import { Box, IconButton } from "@mui/material";
import { Typography } from '@mui/material';
import { setMainTitle } from '../../reduxActions/Actions';
import { connect } from 'react-redux';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import DialogHelp from '../../elements/DialogHelp';
import I18n from '../../commons/Utils';
import api from '../../services/api';

class MobMzReturn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
    }
  }

  async componentDidMount() {

    
    api.get('v2/mz/wa/return/' + this.props.id)
      .then(response => {
        const data = response.data
        this.setState({ data });
        this.props.dispatch(setMainTitle(data.name))
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>{"MzEquityLine" + "Title"}</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              {"MzEquityLine" + "Help"}
            </Trans>
          }
        />
        <Box sx={{ px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: "center" }}>
            <Box sx={{ flex: 1 }}>
              <Typography gutterBottom variant="overline" component="h3"><b>Return from inception</b></Typography>
            </Box>
            <IconButton sx={{ width: 40 }} aria-label="settings" onClick={() => this.openHelpDialog()}>
              <HelpOutlineOutlined />
            </IconButton>
          </Box>

          <Typography className='big' variant="h4" component="h3" >
            {data.ret}
          </Typography>
        </Box>
      </>
    );
  }
}

export default connect()(MobMzReturn);
