import React from "react";
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';

const SkeletonList2 = (props) => {    
    const printSkeleton = (cb) => {
        const row = [];
        for (let i = 0; i < (props.rows ? props.rows : 4); i++) {
            row.push(
                <Box key={i} display="flex" alignItems="center" flexDirection="row" height="48px">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="10%" />
                    </Box>
                </Box>
            );
        }
        return row;
    };
    return (
        <>{printSkeleton()}</>
    );
}

export default SkeletonList2;