const initialState = {
    loginStatus: false,
    loginStatusUpdate: 0,
    title: "Braino"
}

export const reducer = (state = initialState, action) => {
    // console.log("received", action);
    switch (action.type) {
        case 'LOGIN_STATUS':
            return {
                ...state,
                loginStatus: action.value
            }
        case 'LOGIN_STATUS_UPDATE':
            return {
                ...state,
                loginStatusUpdate: (state.loginStatusUpdate+action.value)
            }
        case 'TITLE':
            return {
                ...state,
                title: action.value
            }
        default:
            return state
    }
}
