import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import I18n from '../commons/Utils';
import DialogHelp from '../elements/DialogHelp';
import DialogUpgrade from '../elements/DialogUpgrade';
import SkeletonList from '../elements/SkeletonListAvatar';
import api from '../services/api';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

class ProfileDetailsMob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      upgradeOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      upgradeTitle: t("edit_profile_new"),
      upgradeText: t("edit_profile_deny_text"),
      lang: localStorage.getItem('LANG'),
      snackOpen: false,
      snackMessage: '',
    }
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  closeUpgradeDialog() {
    this.setState({ upgradeOpen: false })
  }

  openUpgradeDialog() {
    this.setState({ upgradeOpen: true })
  }

  async componentDidMount() {
    
    api.get('profiles/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  resetProfile = () => {
    
    api.delete('questions/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ snackOpen: true, snackMessage: "reset done" });
        localStorage.setItem("QST", 1)
        this.props.navigate("/profiling", { replace: true });
      }).catch(error => {
        const httpCode = error.response.status
        if (httpCode === 401) {
          this.setState({ upgradeOpen: true })
        }
      }).finally(() => {
        this.setState({ snackOpen: false, snackMessage: "" });

      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data, snackOpen, snackMessage } = this.state;
    return (
      <>
        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={this.state.helpTitle}
          helpDialogContent={this.state.helpText}
          okButton="ok" />

        <DialogUpgrade
          closeUpgradeDialog={() => this.closeUpgradeDialog()}
          isUpgradeDialogOpen={this.state.upgradeOpen}
          upgradeDialogTitle={this.state.upgradeTitle}
          upgradeDialogContent={this.state.upgradeText}
        />

        {/* <Typography variant="h4" className='big'><Trans>profile_pageTitle</Trans></Typography> */}

        <Box>
          <Typography gutterBottom variant="overline" component="h2">{t("profile_type")}</Typography>
          {data ? (
            <List>
              <ListItem>
                <ListItemText primary={<b>{t("a_riskTitle")}</b>} secondary={<Trans>{data.risk}</Trans>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<b>{t("a_horTitle")}</b>} secondary={<Trans>{data.horizon}</Trans>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<b>{t("a_marketsTitle")}</b>} secondary={data.markets} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<b>{t("a_sectorsTitle")}</b>} secondary={data.sectors} />
              </ListItem>
            </List>
          ) :
            <SkeletonList></SkeletonList>
          }

          <Button variant='contained' size='large' disableElevation onClick={this.resetProfile} color="primary" ><Trans>edit_profile_button</Trans></Button>
        </Box>
      </>
    );
  }
}

export default withParams(ProfileDetailsMob);
