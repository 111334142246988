import { CreditCard, CreditCardTwoTone } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Fade, Tab, Tabs, Toolbar, Tooltip } from "@mui/material";
import { Container } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { connect, useDispatch } from "react-redux";
import { setMainTitle } from "../reduxActions/Actions";
import PaypalCheckout from "./PaypalCheckout";
import StripeCheckout from "./StripeCheckout";

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Payment() {
  const [payment, setPayment] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMainTitle("Payment"))
  }, [])

  const handleChange = (event, newValue) => {
    setPayment(newValue)
  }

  return (
    <Fade in={true} timeout={450}>
      <div>
        {isBrowser ?
          <BrowserView>
            <Container sx={{ p: 2 }}>
              <Toolbar />
              <TabContext value={payment}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList variant="standard" onChange={handleChange} aria-label="basic tabs example">
                    <Tab icon={<Tooltip title="Credit card"><CreditCardTwoTone /></Tooltip>} label="" value={1} />
                    <Tab icon={<Tooltip title="PayPal"><img src="img/cards/PayPal.svg" height={20} /></Tooltip>} value={2} />
                  </TabList >
                </Box>
                <TabPanel value={1}>
                  <Card>
                    <CardHeader></CardHeader>
                    <CardContent sx={{ p: 4 }}>
                      <Elements stripe={promise}>
                        <StripeCheckout />
                      </Elements>
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={2}>
                  <Card sx={{ position: "relative" }}>
                    <CardHeader></CardHeader>
                    <CardContent sx={{ p: 4 }}>
                      <PaypalCheckout />
                    </CardContent>
                  </Card>
                </TabPanel>
              </TabContext>
            </Container>
          </BrowserView>
          :
          <MobileView>
            <Container sx={{ p: 2 }}>
              <Toolbar />
              <TabContext value={payment}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList variant="standard" onChange={handleChange} aria-label="basic tabs example">
                    <Tab icon={<Tooltip title="Credit card"><CreditCardTwoTone /></Tooltip>} label="" value={1} />
                    <Tab icon={<Tooltip title="PayPal"><img src="img/cards/PayPal.svg" height={20} /></Tooltip>} value={2} />
                  </TabList >
                </Box>
                <TabPanel value={1}>
                  <Box>
                    <Elements stripe={promise}>
                      <StripeCheckout />
                    </Elements>
                  </Box>
                </TabPanel>
                <TabPanel value={2}>
                  <Box>
                    <PaypalCheckout />
                  </Box>
                </TabPanel>
              </TabContext>
            </Container>
          </MobileView>
        }
      </div>
    </Fade >
  );
}

export default connect()(Payment)