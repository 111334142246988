import React, { Component } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Fade, MobileStepper, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';

class MobIntroHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      steps: [
        <Fade in={true} timeout={450}>
          <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <img src="/img/intro/IntroMyBraino1.svg" alt='Logo' style={{width: "100%", maxHeight: "50vh"}} />
            <Typography sx={{ mb: 1, textTransform: 'uppercase' }} variant='h4' className='big'>
            <Trans components={{
                highlighter: <Typography variant='inherit' className='big' color='primary' component={'span'}></Typography>
              }}>onboarding_home1_title</Trans>
            </Typography>
            <Box>
              <Typography variant='body1' sx={{ fontWeight: 300 }}>
                <Trans components={{
                  b: <b></b>,
                }}>onboarding_home1_text</Trans>
              </Typography>
            </Box>
          </Box>
        </Fade>,
        <Fade in={true} timeout={450}>
          <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <img src="/img/intro/IntroMyBraino2.svg" alt='Intro1' style={{width: "100%", maxHeight: "50vh"}} />
            <Typography sx={{ mb: 1, textTransform: 'uppercase' }} variant='h4' className='big'>
              <Trans components={{
                highlighter: <Typography variant='inherit' className='big' color='primary' component={'span'}></Typography>
              }}>onboarding_home2_title</Trans>
            </Typography>
            <Box sx={{}}>
              <Typography variant='body1' sx={{ fontWeight: 300 }}>
                <Trans components={{
                  b: <b></b>,
                }}>onboarding_home2_text</Trans>
              </Typography>
            </Box>
          </Box>
        </Fade>,
      ],
    }
  }

  handleNext() {
    this.setState((prevState, props) => ({
      activeStep: prevState.activeStep + 1,
      activeStatus: false
    }));
  }

  handleBack() {
    this.setState((prevState, props) => ({
      activeStep: prevState.activeStep - 1
    }));
  }

  handleEnd() {
    this.props.introClose()
    localStorage.setItem("ONB_HOME", 1)
  }

  render() {
    const { activeStep, steps } = this.state;
    const props = this.props;
    return (
      <div>
        <Dialog open={props.open} onClose={props.introClose} fullScreen>
          <DialogContent>
            <Box sx={{ mb: 7 }}>
              {steps[activeStep]}
            </Box>
          </DialogContent>
          <DialogActions>
            <MobileStepper
              variant="dots"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1, background: 'transparent', borderColor: 'transparent!important' }}
              nextButton={
                <>
                  {activeStep === steps.length - 1 ?
                    <Button disableElevation variant='contained' size="large" onClick={() => this.handleEnd()}>
                      Get started
                    </Button>
                    :
                    <Button disableElevation variant='outlined' size="large" onClick={() => this.handleNext()} endIcon={<KeyboardArrowRight />}>
                      Next
                    </Button>}
                </>
              }
              backButton={
                <Button disableElevation size="large" onClick={() => this.handleBack()} disabled={activeStep === 0} startIcon={<KeyboardArrowLeft />}>

                </Button>
              }
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default MobIntroHome;