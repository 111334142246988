import { withTheme } from '@emotion/react';
import { HelpOutline } from '@mui/icons-material';
import { Button, CardHeader, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axios from 'axios';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import { COLORS } from '../commons/Colors';
import DialogConfirm from '../elements/DialogConfirm';
import DialogHelp from '../elements/DialogHelp';
import SkeletonGauge from '../elements/SkeletonGauge';
import api from '../services/api';

class DetailsScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      options: null,
      helpOpen: false,

      snackOpen: false,
      snackMessage: '',
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    
    api.get('trading/strength/' + this.props.id)
      .then(response => {
        const data = response.data && response.data.length > 0 ? response.data[0] : { open_signal: 0, strength: 0, id_trade: 0, score: 0 }

        let options = {
          series: [data.strength],
          chart: {
            height: '100%',
            type: 'radialBar',
            offsetY: 0
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "40%"
              },
              startAngle: -120,
              endAngle: 120,
              dataLabels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '11px',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  color: '#848484',
                  offsetY: 56
                },
                value: {
                  show: true,
                  fontSize: "25px",
                  fontFamily: 'Fjalla One',
                  color: '#484848',
                  offsetY: 24,
                  formatter: function (val) {
                    return val ? numeral(val).format('0.0') : '-';
                  }
                },
              },
              track: {
                background: '#e6e6e6',
                startAngle: -120,
                endAngle: 120,
              },
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          colors: [data && data.open_signal === 'B' ? this.props.theme.palette.buy.main : data && data.open_signal === 'B' ? this.props.theme.palette.sell.main : COLORS.lightgrey],
          fill: {
            type: 'solid',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50]
            },
          },
          labels: ['Brainopoints'],
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }
          },
        };
        this.setState({ data, options });

      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  confirmTradeOpen(id) {
    console.log("apro posizione trade ", this.state.posConfirmTradeId)
    
    api.post('mypos/' + localStorage.getItem('ID') + '/' + id + '/theta2', '')
      .then(response => {
        const data = response.data
        if (data > 0) {
          this.setState({ snackOpen: true, snackMessage: t('MyPositions_added_text') });
        } else {
          this.setState({ helpTitle: 'Warning', helpText: t("MyPositions_too_many_text"), helpOpen: true })
        }
      }).catch(error => {
        console.log(error);
        // HANDLE ERROR HERE (ERROR SNACKBAR)
      })
    this.setState({ posConfirmOpen: false, posConfirmTradeId: id });
  }

  openPosConfirm() {
    this.setState({ posConfirmOpen: true });
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false, posConfirmTradeId: 0 });
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  render() {
    const { data, posConfirmOpen, snackOpen, snackMessage, isLoading } = this.state;
    let signal = data.open_signal === 'B' ? 'buy' : 'sell';

    return (
      <>

        <DialogConfirm
          id={this.state.posConfirmTradeId}
          closeLoginDialog={() => this.closePosConfirm()}
          isLoginDialogOpen={posConfirmOpen}
          confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
          posDialogTitle={t('my_addconfirmTitle')}
          posDialogContent={<Trans components={{ i: <i></i> }}>my_addconfirm_desc</Trans>}
          cancelButton="cancel"
          okButton="ok" />

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("DetailsScoreTitle")}
          helpDialogContent={<Trans components={{ em: <em></em> }}>{"DetailsScoreHelp"}</Trans>}
          okButton="ok" />

        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <Card variant="outlined">
          <CardHeader sx={{ p: '16px 16px 0 16px!important' }}
            title={<Typography variant="overline">{t("DetailsScoreTitle")}</Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutline />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ p: '0!important' }}>
            <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexBasis: 0 }}>
              <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {   
                  isLoading ? <SkeletonGauge width={80} height={80} /> :
                  data && this.state.options ?
                  <ReactApexChart options={this.state.options} series={this.state.options.series} type="radialBar" height={150} />
                  :  '-'}
              </Box>
              <Box sx={{ flex: 1, p: 2 }}>
                <Box sx={{ display: 'inline-flex', flexDirection: 'column', flexBasis: 0 }}>
                  <Box><Typography variant='h6'>Braino Score</Typography></Box>
                  <Box><Typography variant='caption'>
                    {data.strength ?
                      <>
                        <Trans 
                        values={{ strength: numeral(data.strength).format('0.0'), signal }}>
                          {"DetailsScore_comment_" + data.score}
                        </Trans>
                      </>
                      : <Trans>DetailsScore_comment_empty</Trans>
                    }
                  </Typography></Box>
                  <Box>
                    {
                      data.open_signal ?
                        <Button
                          onClick={() => this.addPosition(data.id_trade)}
                          size='medium' variant='contained' sx={{ mt: 2, minWidth: '120px' }} color={data.open_signal === 'B' ? 'buy' : (data.open_signal === 'S' ? 'sell' : 'secondary')} disableElevation>{data.open_signal === 'B' ? 'BUY' : (data.open_signal === 'S' ? 'SELL' : '-')}</Button>
                        : <Button component={NavLink} to='/mybraino' size='medium' variant='outlined' sx={{ mt: 2, minWidth: '120px' }} color={'primary'} disableElevation>Back</Button>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(DetailsScore);
