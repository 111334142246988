import { Box } from "@mui/material";
import { Component } from "react";

export class DragElement extends Component {
    render() {
        return (
            <Box sx={{
                background: "#e6e6e6",
                width: 100,
                height: 7,
                borderRadius: 50,
            }} />
        )
    }
}
