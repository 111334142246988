import React, { Component } from 'react';
import { Box, Button, Container, Divider, Fade, Grid, Toolbar, Typography } from '@mui/material';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { NavLink, useParams } from 'react-router-dom';
import SkeletonListAvatar from '../elements/SkeletonListAvatar';
import numeral from 'numeral';
import moment from 'moment/moment';
import api from '../services/api';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: false,
    }
  }

  componentDidMount() {
    let { id } = this.props.params;
    this.setState({ id, isLoading: true })

    api.get('invoices/' + localStorage.getItem('ID') + '/' + id)
      .then(response => {
        const data = response.data
        this.setState({ data, isLoading: false });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { data, isLoading } = this.state;
    let subtotal, tax, paid, paid_tax, discount, discount_formatted, paid_tax_formatted, total = 0
    if (data) {
      subtotal = numeral(data.price).format('0,0[.]00') + ' €';
      tax = data.tax;
      paid = data.paid_price ? data.paid_price : data.price;
      paid_tax = data.paid_tax ? data.paid_tax : tax;
      discount = (data.price - paid);
      discount_formatted = numeral(-1 * discount).format('0,0[.]00') + ' €';
      paid_tax_formatted = numeral(paid_tax).format('0,0[.]00') + ' €';
      total = numeral(data.price * 1 - discount * 1 + paid_tax * 1).format('0,0[.]00') + ' €';
    }
    return (
      <Fade in={true} timeout={450}>
        <div>
          {isBrowser ?
            <BrowserView>
              <Toolbar /><Toolbar />
              {
                isLoading ? <SkeletonListAvatar /> :
                  data ?
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <img src="/img/logo_wavenure_black.png" alt='Logo' width={150} />
                          <Typography>Wavenure srl, Milan</Typography>
                          <Typography>Wavenure.com</Typography>
                          <Typography>VAT 09355870966</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography gutterBottom variant='h6'>Customer data</Typography>
                          <Typography>{data.first_name} {data.last_name}</Typography>
                          <Typography>{data.email}</Typography>
                          <Typography>{data.country}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='h6'>Invoice N.{data.invoice_number}</Typography>
                          <Divider sx={{ borderColor: '#848484!important' }} />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant='h6'>DATE</Typography>
                          <Typography>{data.payment_date}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant='h6'>PRODUCT</Typography>
                          <Typography>{data.description}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant='h6'>DESCRIPTION</Typography>
                          <Typography>Web subscription</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant='h6'>PERIOD</Typography>
                          <Typography>{moment(data.payment_date).format('DD.MM.YYYY')} / {moment(data.expiration).format('DD.MM.YYYY')}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                          <Typography variant='h6'>SUBTOTAL</Typography>
                          <Typography>{subtotal}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mb: 7 }} ></Grid>

                        <Grid item xs={6}>
                          <Typography variant='h6'>DISCOUNT</Typography>
                          <Typography>TAX ({data.tax_perc}%)</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                          <Typography variant='h6'>{discount_formatted}</Typography>
                          <Typography>{paid_tax_formatted}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant='h6'>TOTAL</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                          <Typography variant='h6'>{total}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider sx={{ borderColor: '#848484!important' }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Payment made by Credit card via e-commerce</Typography>
                        </Grid>

                      </Grid>
                      <Box sx={{ mt: 3 }}><Button component={NavLink} to={'/invoices'}>Back</Button></Box>
                    </>
                    : 'no data'
              }
            </BrowserView>
            :
            <MobileView>
              <Fade in={true} timeout={450}>
                <div>
                  <Toolbar />
                  <Container sx={{ p: 2 }}>
                    {
                      isLoading ? <SkeletonListAvatar /> :
                        data ?
                          <>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <img src="https://dev.braino.ai/img/logo_wavenure_black.png" alt='Logo' width={150} />
                                <Typography>Wavenure srl, Milan</Typography>
                                <Typography>Wavenure.com</Typography>
                                <Typography>VAT 09355870966</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography gutterBottom variant='h6'>Customer data</Typography>
                                <Typography>{data.first_name} {data.last_name}</Typography>
                                <Typography>{data.email}</Typography>
                                <Typography>{data.country}</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant='h6'>Invoice N.{data.invoice_number}</Typography>
                                <Divider sx={{ borderColor: '#848484!important' }} />
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant='h6'>DATE</Typography>
                                <Typography>{data.payment_date}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant='h6'>PRODUCT</Typography>
                                <Typography>{data.description}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant='h6'>DESCRIPTION</Typography>
                                <Typography>Web subscription</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant='h6'>PERIOD</Typography>
                                <Typography>{moment(data.payment_date).format('DD.MM.YYYY')} / {moment(data.expiration).format('DD.MM.YYYY')}</Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Typography variant='h6'>SUBTOTAL</Typography>
                                <Typography>{subtotal}</Typography>
                              </Grid>

                              <Grid item xs={12} sx={{ mb: 7 }} ></Grid>

                              <Grid item xs={6}>
                                <Typography variant='h6'>DISCOUNT</Typography>
                                <Typography>TAX ({data.tax_perc}%)</Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant='h6'>{discount_formatted}</Typography>
                                <Typography>{paid_tax_formatted}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant='h6'>TOTAL</Typography>
                              </Grid>
                              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant='h6'>{total}</Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Divider sx={{ borderColor: '#848484!important' }} />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>Payment made by Credit card via e-commerce</Typography>
                              </Grid>

                            </Grid>
                            <Box sx={{ mt: 3 }}><Button component={NavLink} to={'/invoices'}>Back</Button></Box>
                          </>
                          : 'no data'
                    }
                  </Container>
                </div>
              </Fade>
            </MobileView>
          }
        </div>
      </Fade>
    );
  }
}

export default withParams(Invoice);