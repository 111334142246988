import { CheckOutlined } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';

class ProfilingSectors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: []
    }
  }

  componentDidMount() {
  }

  handleChange(value) {
    let selected = this.state.selected
    if (!selected.includes(value)) {
      selected.push(value)
    } else {
      selected = selected.filter(function (v, index, arr) {
        return v !== value;
      })
    }
    this.setState({ selected })
    this.props.handleChange(this.props.q, selected)
    this.props.handleStatus(this.props.q, selected.length > 2 ? true : false)
  }

  render() {
    const { selected } = this.state;
    return (
      <Box sx={{ py: 2 }}>
        <Typography gutterBottom variant='body2'><Trans components={{b: <b></b>}}>q_4</Trans></Typography>
        <Grid container spacing={1}>
          {[
            { 'l': t("a_sec_1"), 'v': 1 },
            { 'l': t("a_sec_2"), 'v': 2 },
            { 'l': t("a_sec_4"), 'v': 4 },
            { 'l': t("a_sec_7"), 'v': 7 },
            { 'l': t("a_sec_9"), 'v': 9 },
            { 'l': t("a_sec_10"), 'v': 10 },
            { 'l': t("a_sec_11"), 'v': 11 },
            { 'l': t("a_sec_14"), 'v': 14 },
            { 'l': t("a_sec_15"), 'v': 15 },
            { 'l': t("a_sec_19"), 'v': 19 },
            { 'l': t("a_sec_22"), 'v': 22 },
          ].map((item) => {
            const checked = selected.includes(item.v);
            return (
              <Grid item xs={6} sm={4} md={4} lg={3}>
                <Chip
                  sx={{ m: 1, minWidth: "100%" }}
                  size='medium'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  icon={checked ? <CheckOutlined /> : ''}
                  onClick={() => {
                    this.handleChange(item.v);
                  }}
                >

                </Chip>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default withTranslation()(ProfilingSectors);