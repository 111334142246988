import { withTheme } from '@emotion/react';
import { Check } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Collapse, Container, Fade, Grid, List, ListItem, ListItemAvatar, ListItemText, Switch, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import React, { Component } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SkeletonChart from '../elements/SkeletonChart';
import { notifyLogout } from '../reduxActions/Actions';
import api from '../services/api';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      lang: localStorage.getItem('LANG'),
      data: null,
      billingType: true
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) {
      this.props.dispatch(notifyLogout())
    }

    this.setState({ isLoading: true })

    api.get('shop/products/')
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleChange(type, value) {
    this.setState({ [type]: value })
    console.log(this.state)
  }

  render() {
    const { isLoading, data, billingType } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          {isBrowser ?
            <BrowserView>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} spacing={3}>
                    <Box sx={{ display: 'flex', p: 2, justifyContent: 'center', alignItems: 'center' }}>
                      <Trans>ecommerce_period_month</Trans>
                      <Switch checked={billingType} onChange={e => this.handleChange('billingType', e.target.checked)}></Switch>
                      <Trans>ecommerce_period_year</Trans>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                      {
                        isLoading ? <SkeletonChart width={450} /> :
                          data && data.length > 0 ? data.map((obj) => (
                            obj.published === '1' ?
                              <Card sx={{ width: 450, borderWidth: "3px!important", borderColor: `${this.props.theme.palette.chartPrimary}!important` }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'start', py: 2 }}>
                                    <Typography sx={{ fontWeight: 300, mb: 3 }} variant="h5" component="h2" className='big'>{obj.name}</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="body1" component="p" sx={{ alignSelf: 'flex-start', fontWeight: "bold" }}> &euro; </Typography>
                                    <Typography color={'primary'} className='big' variant="h1" sx={{ lineHeight: 1 }}>
                                      {billingType ?
                                        obj.front_discounted_price
                                        : obj.front_price
                                      }
                                    </Typography>
                                    <Typography gutterBottom variant="body1" component="p" sx={{ alignSelf: 'flex-end', fontWeight: "bold" }}> <Trans>ecommerce_each_month</Trans> </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
                                    <Typography gutterBottom variant="body2" color={'secondary'} component="h2" sx={{ fontWeight: 300 }}>
                                      {billingType ?
                                        <Trans values={{ amount: numeral(obj.front_discounted_price * 12).format('0.[00]') }}>billed_annually</Trans>
                                        : ""
                                      }
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <List dense={true}>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_1</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_2</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_3</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_4</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_5</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_6</Trans></ListItemText>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                        <ListItemText color='secondary'><Trans>plan_feature_7</Trans></ListItemText>
                                      </ListItem>
                                    </List>
                                  </Box>
                                </CardContent>
                                <CardActions sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                                  <Button size='large' variant='contained' disableElevation sx={{ minWidth: 250 }} component={NavLink} to={"/payment"}><Trans>ecommerce_subscribe</Trans></Button>
                                </CardActions>
                              </Card>
                              : ''
                          )) : 'Server error'

                      }
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </BrowserView>
            :
            <MobileView>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', p: 2, justifyContent: 'center', alignItems: 'center' }}>
                      <Trans>ecommerce_period_month</Trans>
                      <Switch checked={billingType} onChange={e => this.handleChange('billingType', e.target.checked)}></Switch>
                      <Trans>ecommerce_period_year</Trans>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                      {data && data.length > 0 ? data.map((obj) => (
                        obj.published === '1' ?
                          <Card sx={{ width: 450 }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box sx={{ display: 'flex', justifyContent: 'start', py: 2 }}>
                                <Typography sx={{ fontWeight: 300, mb: 3 }} variant="h5" component="h2" className='big'>{obj.name}</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography gutterBottom variant="body1" component="p" sx={{ alignSelf: 'flex-end', mr: 1 }}> &euro; </Typography>
                                <Typography color={'primary'} className='big' variant="h3" component="h3">
                                  {billingType ?
                                    obj.front_discounted_price
                                    : obj.front_price
                                  }
                                </Typography>
                                <Typography gutterBottom variant="body1" component="p" sx={{ alignSelf: 'flex-end', ml: 1 }}><Trans>ecommerce_each_month</Trans></Typography>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
                                <Typography gutterBottom variant="body2" color={'secondary'} component="h2">
                                  {billingType ?
                                    <Trans values={{ amount: numeral(obj.front_discounted_price * 12).format('0.[00]') }}>billed_annually</Trans>
                                    : ""
                                  }
                                </Typography>
                              </Box>
                              <Collapse in={true}>
                                <List dense={true}>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_1</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_2</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_3</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_4</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_5</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_6</Trans></ListItemText>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemAvatar><Check color='primary' /></ListItemAvatar>
                                    <ListItemText color='secondary'><Trans>plan_feature_7</Trans></ListItemText>
                                  </ListItem>
                                </List>
                              </Collapse>
                            </CardContent>
                            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Button size='large' variant='contained' component={NavLink} to={"/payment"}><Trans>ecommerce_subscribe</Trans></Button>
                            </CardActions>
                          </Card>
                          : ''
                      )) : 'altro'

                      }
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </MobileView>
          }
        </div>
      </Fade>
    );
  }
}

export default withTheme(connect()(Plans));